import React from 'react';

import AddressDetail from './detail'

export default function AddressContainer({shipment}) {
    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <AddressDetail shipment={shipment}/>
        </React.Suspense>
    );
}
