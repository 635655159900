import { Box, Checkbox, FormControlLabel, LinearProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';

import { addressHistoriesState, isLoadingState, useAddressHistories } from '../../states/address'
import { EventBloc } from '../ShipmentEventsContainer'

function AddressHistories() {
    const getAddressHistories = useAddressHistories();
    const [events] = useRecoilState(addressHistoriesState);
    const [isLoading] = useRecoilState(isLoadingState);
    const [isCustomerProfile, setIsCustomerProfile] = useState(false);

    useEffect(() => {
        getAddressHistories();
    }, []);

    let shownEvents = events || [];
    if(isCustomerProfile) {
        shownEvents = events?.filter(e => e.category == 'CUSTOMER_PROFILE') || []
    }
    return isLoading ? <LinearProgress/> : (
        <div style={{height: 'calc(100% - 4px)', position: 'relative', padding: 0}}>
            <Box>
                <FormControlLabel control={<Checkbox color='primary' checked={isCustomerProfile} onChange={ (e,b) => setIsCustomerProfile(b) } />} label="Show customer profile event only" />
            </Box>
            <div style={{position: 'absolute', padding: '10px 0', bottom: 0, top: 30, overflow: 'auto', minWidth: '100%'}}>
                {events?.length == 0 ? <Box display='flex' justifyContent='center' mt={2}>No data found</Box> :<EventBloc events={shownEvents} />}
            </div>
        </div>)
}

export default function AddressHistoryContainer() {
    return <React.Suspense fallback={ <LinearProgress /> }>
        <AddressHistories />
    </React.Suspense>
}