import { atomFamily, selector } from 'recoil';

import { regionRepo } from '../repositories';

const regionAtom = atomFamily({
    'key': 'region-family-atoms'
})

export const allRegionSelector = selector({
    'key': 'all-region',
    get: async () => {
      let data = await regionRepo.list();
      const regions = data.map(({properties}) => ({ label: properties.display_name, value: properties.code }));
      return regions;
    }
})