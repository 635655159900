import React,{ useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'

import { searchQueryState, selectedShipmentState, shipmentSearchSelector } from '../../states/shipment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import OutlinedInput from "@material-ui/core/OutlinedInput";

import DeliveryHistoryItem from '../HistoryItem';

function DeliverySearchResult() {
    const shipments = useRecoilValue(shipmentSearchSelector)
    return <div>
        { shipments && shipments.items.map(shipment => <DeliveryHistoryItem key={shipment.id} showCustomer={true} showAddress={true} shipment={shipment} />)}
    </div>
}

export default function DeliverySearchContainer() {
    const shipment = useRecoilValue(selectedShipmentState)
    const setQuery = useSetRecoilState(searchQueryState)
    const [customQuery, setCustomQuery] = useState()
    const updateQuery = () => {
        setQuery({from: 0, size: 15, q: customQuery})
    }
    useEffect(() => {
        if (!shipment) {
            setQuery({from:0, size: 15, q: 'NONE'})
        } else {
            const q = `${shipment.customer.name} ${shipment.dropoff_address.street} ${shipment.dropoff_address.city} ${shipment.dropoff_address.zipcode}`;
            setQuery({from:0, size: 15, q});
            setCustomQuery(q)
        }
    }, [shipment])

    return <div style={{height: 'calc(100% - 4px)', position: 'relative', padding: 0}}>
        <div style={{display: 'flex', height: 40}}>
            <div style={{flex: 1}}>
                <OutlinedInput onKeyPress={(e) => e.key === 'Enter' && updateQuery(e)} fullWidth style={{height: 40}} value={customQuery} onChange={(e) => setCustomQuery(e.target.value)} />
            </div>
            <div>
                <IconButton onClick={updateQuery}><SearchIcon /></IconButton>
            </div>
        </div>
        <div style={{position: 'absolute', padding: 10, overflow: 'auto', paddingLeft: 0}}>
        <React.Suspense fallback={<div>Loading...</div>}>
            <DeliverySearchResult />
        </React.Suspense>
        </div>
    </div>
}
