import React from 'react';

import { Typography, Grid } from "@material-ui/core";

import { H4 } from '../Heading';
import Copiable from '../Copiable';
import { Apple, Google } from '../Icons'
import { Search } from '@material-ui/icons';

export function Diff({str, orig}) {
    const isDiff = (str && !orig) || (orig && !str) || (str && orig && str.toUpperCase() !== orig.toUpperCase())
    return <>
        { isDiff && <Typography style={{textDecoration: 'line-through', color: '#aaa', fontSize: '0.8em'}}>{ orig}</Typography> }
        <Typography style={{color: isDiff ? '#3a6a4a' : '#4a4a4a'}}>{str}</Typography>
    </>
}

export default function AddressInfoBox({address, origin}) {
    const fullAddress = `${address.street} ${address.street2 || ''}, ${address.city}, ${address.state} ${address.zipcode}`
    return <Grid container>
        <Grid item xs={12}>
            <H4>Street</H4>
            <Diff str={address.street} orig={origin ? origin.street : null} />
            {/* <Typography>{address.street}</Typography> */}
        </Grid>
        { (address.street2 || (origin && origin.street2)) && <Grid item xs={12}>
            <H4>Street2</H4>
            <Diff str={address.street2} orig={origin ? origin.street2 : null} />
        </Grid> }
        <Grid item container xs={12}>
            <Grid item xs={5}>
                <H4>City</H4>
                <Diff str={address.city} orig={origin ? origin.city : null} />
            </Grid>
            <Grid item xs={3}>
                <H4>State</H4>
                <Diff str={address.state} orig={origin ? origin.state : null} />
            </Grid>
            <Grid item xs={4}>
                <H4>Zipcode</H4>
                <Diff str={address.zipcode} orig={origin ? origin.zipcode : null} />
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <H4>Full address</H4>
            <Typography style={{ fontSize: '0.9em' }}>
                <Copiable txt={ fullAddress } />
                <span style={{display: 'inline-block'}}>
                <a style={{marginLeft: 10}} target='_blank' rel="noreferrer" href={`https://maps.google.com/?q=${fullAddress.replaceAll('#',' ')}`}><Google size='md' /></a>
                <a style={{marginLeft: 10}} target='_blank' rel="noreferrer" href={`https://maps.apple.com/?address=${fullAddress.replaceAll('#',' ')}`}><Apple /></a>
                <a style={{marginLeft: 10}} target='_blank' rel="noreferrer" href={`https://google.com/search?q=${fullAddress.replaceAll('#',' ')}`}><Search style={{marginBottom: -4, color: '#444'}} fontSize='small' /></a>
                </span>
            </Typography>
        </Grid>
    </Grid>

}
