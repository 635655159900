import React, { useState } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { Box, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

import { selectedShipmentInfoState } from '../../states/shipment';
import CodeBloc from '../../components/CodeBloc';
import GpsLocation from '../GpsLocation'
import SuggestableGpsLocation from '../SuggestableGpsLocation'
import HistoryItem from '../../components/HistoryItem';

const UID_TYPE_NAME = {
    SH: 'Shipment',
    ST: 'Stop',
    DR: 'Driver',
    US: 'User',
    WO: 'Worker',
    EH: 'Event Handler',
}
const eventTypeName = (t) => UID_TYPE_NAME[t] || ''

const ADDRESS_FIELD_NAME = {
    street: 'Street',
    street2: 'Street 2',
    city: 'City',
    state: 'State',
    zipcode: 'Zipcode',
    location: 'Location',
    pin_verified: 'Pin verified',
    instructions: 'Special Instructions',
    access_code: 'Access Code'
}
const addressFieldName = (f) => ADDRESS_FIELD_NAME[f] || f

const PROFILE_TYPE_NAME = {
    CUSTOMER_PROFILE: 'Customer Profile',
    DELIVERABLE_ADDRESS: 'Delivery Address',
}
const profileTypeName = (p) => PROFILE_TYPE_NAME[p] || p

const ignoreKeys = ['old_customer_profile_id', 'known_access_codes', 'total_customer_positive_feedbacks', 'total_customer_negative_feedbacks', 'accuracy'];

const ACTION_NAME = {
    update_dropoff: 'update',
    update_pickup: 'update',
    update_status: 'update',
    'update-inbound': 'update',
    'correct-address': 'update'
}
const actionName = (t) => ACTION_NAME[t] || t

const STATE_KEYS = ['status', 'remark', 'reason', 'inbound_status', 'text','access_code', 'instruction', 'dropoff_latitude', 'dropoff_longitude']

function EventUser({o, id}) {
    const {attributes} = o || {}
    const {name} = attributes || {}
    return <span style={{padding: 2}} title={`ID: ${id}`}><CodeBloc><span style={{color: '#888'}}>User</span> {name || id} </CodeBloc></span>
}

function EventDriver({o, id}) {
    const {attributes} = o || {}
    const {name} = attributes || {}
    return <span style={{padding: 2}} title={`ID: ${id}`}><CodeBloc><span style={{color: '#888'}}>Driver</span> {name || id} </CodeBloc></span>
}

function EventObject({o}) {
    const {uid} = o || {}
    const comps = uid.split('_')
    const type = comps.length > 0 ? comps[0] : ''
    
    const id = comps[1]
    if (type === 'US') return <EventUser o={o} id={id} />
    if (type === 'DR') return <EventDriver o={o} id={id} />
    return <span style={{padding: 2, justifySelf: 'baseline', justifyItems: 'baseline'}}><CodeBloc><span style={{color: '#888'}}>{eventTypeName(type)}</span> {id}</CodeBloc></span>
}

function EventGPS({event}) {
    const {location, action} = event || {}
    const {geolocation} = location || {}
    const {latitude, longitude} = geolocation || {}
    return action === 'picture' ? <SuggestableGpsLocation lat={latitude} lng={longitude} size={'sm'} hideNumber={true} google={true} />
        : <GpsLocation lat={latitude} lng={longitude} size={'sm'} hideNumber={true} google={true} />
}

function EventState({state}) {
    if (!state) return <></>
    const keys = STATE_KEYS.filter(x => state[x])
    if (keys.length < 1) return <></>
    return <div style={{padding: 5, border: 'dashed 1px #eee', borderRadius: 4}}>
        { keys.map(p => <div key={p}><span style={{color: '#888'}}>{p} :</span> {state[p]}</div>)}
    </div>   
}

function EventAddressHistory({event}) {
    if(!event) return null;
    const { ts, action, category } = event || {}
    return <Box position={'relative'} display='flex' style={{padding: '4px'}}>
        <HistoryItem dotLeft={-11} event={event}>
            <Box flex={1}>
                <span style={{fontSize: '0.8em', color: '#888'}}>[{moment(ts).format('HH:mm')}]</span>
                <EventObject o={event.subject} />
                <span>{actionName(action)}</span> &nbsp;
                <span style={{backgroundColor: '#f8f8f8', padding: 2}}>
                    {profileTypeName(category)}
                </span>
                <Box ml={2} border={'1px dashed #888'} p={1} mt={1}>
                    {event?.state && Object.keys(event.state)?.filter(item => !ignoreKeys.includes(item))?.map((key, idx) => (
                        <AddressHistoryItem field={key} event={event} key={idx}/>
                    ))}
                </Box>
            </Box>
        </HistoryItem>
    </Box>
}

function AddressHistoryItem({field, event}) {
    const {fact, state} = event;
    let {location: locationFact} = fact || {};
    let {location: locationState} = state || {};
    if(locationFact) {
        locationFact = JSON.parse(locationFact);
    }
    if(locationState) {
        locationState = JSON.parse(locationState);
    }
    return (
        <>
            {field == 'location' && (
                <Box>
                    <Box display='flex' alignItems='center'>
                        <Box>
                            <span style={{fontStyle: 'italic', display: 'block'}}>latitude: {_.get(locationFact, 'latitude', '-')}</span>
                            <span style={{fontStyle: 'italic', display: 'block'}}>longitude: {_.get(locationFact, 'longitude', '-')}</span>
                        </Box>
                        <Box display='inline-block' mx={1} style={{textDecoration: 'underline'}}>to</Box>
                        <Box>
                            <span style={{fontStyle: 'italic', display: 'block'}}>latitude: {_.get(locationState, 'latitude', '-')}</span>
                            <span style={{fontStyle: 'italic', display: 'block'}}>longitude: {_.get(locationState, 'longitude', '-')}</span>
                        </Box>
                    </Box>
                </Box>
            )}
            {field != 'location' && (
                <Box>
                    <span>{addressFieldName(field)}: </span>
                    <span style={{fontStyle: 'italic'}}>{_.get(fact, field, '-')}</span>
                    <Box display='inline-block' mx={1} style={{textDecoration: 'underline'}}>to</Box>
                    <span style={{fontStyle: 'italic'}}>{_.get(state, field, '-')}</span>
                </Box>
            )}
        </>
    )
}

const Dot = ({color, handleToggleModal}) => <div style={{position: 'absolute', top: 10, left: -11, width: 7, height: 7, borderRadius: 5, backgroundColor: color, cursor: 'pointer'}} onClick={handleToggleModal}></div>

function EventDetail({event}) {
    const { state, ts, action, evidence } = event || {}
    const { status, inbound_status } = state || {}
    const statusColor = (s) => {
        if (!s) return '#ccc'
        if (['SUCCEEDED', 'GEOCODED', 'DROPOFF_SUCCEEDED', 'RECEIVED_OK'].indexOf(s) >=0 ) return '#77b45c'
        if (['READY'].indexOf(s) >=0 ) return '#f5a623'
        if (['FAILED', 'GEOCODE_FAILED', 'DROPOFF_FAILED'].indexOf(s) >=0 ) return '#f4485e'
        return '#ccc'
    }
    if (event.type === 'timeline') return <div style={{position: 'relative', padding: 4, justifyItems: 'baseline'}}>
        <Dot color={'#ccc'} />
        <div style={{display: 'flex'}}>
            <div style={{flex: 1, position: 'relative'}}>
                <div style={{position: 'absolute', left: 0, right: 0, top: 10, height: 1, backgroundColor: '#ccc'}}></div>
            </div>
            <div style={{width: 100, textAlign: 'center'}}>
                <CodeBloc style={{padding: '2px 16px', minWidth: 60, border: 'solid 1px #ccc', fontSize: 13}}>{moment(ts).format('MMM DD')}</CodeBloc>
            </div>
            <div style={{flex: 1, position: 'relative'}}>
                <div style={{position: 'absolute', left: 0, right: 0, top: 10, height: 1, backgroundColor: '#ccc'}}></div>
            </div>
        </div>
    </div>
    const showState = ['sms', 'picture','edit_delivery'].indexOf(action) < 0
    const showEvidence = ['sms','edit_delivery'].indexOf(action) > 0

    if(event.category === 'CUSTOMER_PROFILE' || event.category === 'DELIVERABLE_ADDRESS') return <EventAddressHistory event={event} />
    
    return <div style={{position: 'relative', padding: 4, justifyItems: 'baseline'}}>
        <HistoryItem status={status || inbound_status} event={event} dotLeft={-11}>
            <span style={{fontSize: '0.8em', color: '#888'}}>[{moment(ts).format('HH:mm')}]</span>
            <EventObject o={event.subject} />
            { actionName(action) }
            <EventObject o={event.object} />
            <EventGPS event={event} />
            {showEvidence && <EventState state={evidence} />}
            {showState && <EventState state={state} /> }
        </HistoryItem>
    </div>
}

const addTimeline = (events) => {
    if (!events || events.length < 1) return []
    let date = moment('2010-01-01')
    let timelined = []
    events.forEach (e => {
        const day = moment(e.ts).startOf('day')
        if (day.isAfter(date)) {
            timelined.push({
                'type': 'timeline',
                ts: day
            })
        }
        date = day
        timelined.push(e)
    })
    return timelined
}

const buildTree = (events) => {
    let eventMap = {}
    let wrappers = events.map(e => {
        const w = {...e, subs: [], processed: false}
        eventMap[w.id] = w
        return w
    })
    for (let event of wrappers) {
        if (event.origin &&  eventMap[event.origin]) {
            event.processed = true
            eventMap[event.origin].subs.push(event)
        }
    }
    return wrappers.filter(e => !e.processed)
}

export function EventBlocInner({events}) {
    return <div style={{paddingLeft: 13, position: 'relative'}}>
        <div style={{position: 'absolute', top: 15, left: 5, width: 1, bottom: 5, backgroundColor: '#ccc'}}>
        </div>
        { events && events.map((event, idx) => <div key={event.id || event.created || idx}>
            <EventDetail event={event} />
            { event.subs && event.subs.length > 0 && <div style={{paddingLeft: 10, position: 'relative'}}>
                <div style={{position: 'absolute', top: 13, left: -6, height: 1, width: 15, backgroundColor: '#ccc'}}></div>
                <EventBlocInner events={event.subs} />
            </div>}
        </div> )}
    </div>
}

export function EventBloc({events}) {
    const timelined = addTimeline(buildTree(events))

    return <EventBlocInner events = {timelined} />
}

function ShipmentEvents() {
    const info = useRecoilValue(selectedShipmentInfoState)
    const {events} = info || {}
    const [filterDropoff, setFilterDropoff] = useState(true)
    const shown = events ? events
    .filter(e => !filterDropoff || (e.object.attributes && e.object.attributes.type === 'DROP_OFF' && e.subject.uid.indexOf('DR_') >= 0) )
    : []
    const extraEvents = events ? events.filter(e => ("edit_delivery" === e.action && e.evidence)) : [];
    if(filterDropoff && extraEvents.length > 0) shown.push(...extraEvents);
    return <div style={{height: 'calc(100% - 4px)', position: 'relative', padding: 0}}>
        <div>
            <FormControlLabel control={<Checkbox color='primary' checked={filterDropoff} onChange={ (e,b) => setFilterDropoff(b) } />} label="Dropoff Events Only" />
        </div>
        <div style={{position: 'absolute', padding: '10px 0', bottom: 0, top: 40, overflow: 'auto', minWidth: '100%'}}>
            <EventBloc events={shown} />
        </div>
    </div>
}

export default function ShipmentEventsContainer() {
    return <React.Suspense fallback={ <LinearProgress /> }>
        <ShipmentEvents />
    </React.Suspense>
}