import {Grid, Container, Typography, CircularProgress, Backdrop} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import GeocodingMap from '../../components/GeocodingMap';
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {deliverableAddressRepo} from '../../repositories';
import { H5, H4 } from '../../components/Heading'
import { ColorBox } from '../../components/Box';
import toast from "react-hot-toast";
import Copiable from "../../components/Copiable";
import {Diff} from "../../components/AddressInfoBox";
import {useNavigate} from "react-router";
import {elevatorIcon, entranceIcon, parkingIcon, stairsIcon, unitIcon} from "../../constants/mapIcons";


export default function AddressValidateForm({orig, onClose, onSave}) {
    const [address, setAddress] = useState(orig)
    const [latLng, setLatLng] = useState(null)
    const [center, setCenter] = useState()
    const [geocoded, setGeocoded] = useState()
    const [zoom, setZoom] = useState(16)
    const [validation,setValidation] = useState()
    const [extraInfo,setExtraInfo] = useState(null)
    const [addressComponents,setAddressComponents] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const [error, setError] = useState()
    const [flagged, setFlagged] = useState([])
    const [originalInput, setOriginalInput] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        setAddress(orig)
        setLatLng(orig && orig.lat ? {lat: orig.lat, lng: orig.lng} : null)
        setValidation(null)
    }, [orig])

    useEffect(() => {
        clearForm()
    }, [])

    const validate = async () => {

        setError(null)
        setLoading(true)
        let params = {...address, force_true:'true'}
        const response = await deliverableAddressRepo.get(params)

        if(response.status == 200){
            const geocode = response.data.geocode

            setCenter({lat: geocode.latitude, lng: geocode.longitude})
            const mainMarker = response.data.waypoints ? response.data.waypoints.filter(l=>l.type === 'UNIT').map( l => {

                const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
                return point
            }) : []
            const unitMarker = response.data.waypoints ? response.data.waypoints.filter(l=>l.type === 'UNIT').map( l => {

                const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
                return point
            }) : []
            const entranceMarker = response.data.waypoints ? response.data.waypoints.filter(l=>l.type === 'ENTRANCE').map( l => {

                const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
                return point
            }) : []
            const stairMarker = response.data.waypoints ? response.data.waypoints.filter(l=>l.type === 'STAIR').map( l => {

                const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
                return point
            }) : []
            const elevatorMarker = response.data.waypoints ? response.data.waypoints.filter(l=>l.type === 'ELEVATOR').map( l => {

                const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
                return point
            }) : []
            const parkingMarker = response.data.waypoints ? response.data.waypoints.filter(l=>l.type === 'PARKING').map( l => {
                const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
                return point
            }) : []


            const tempLatLng = []
                .concat(mainMarker && mainMarker.length > 0 ? [{latLng: mainMarker, icon:unitIcon}] : [])
                .concat(unitMarker.map(l => Object.assign({}, {latLng: l, icon: unitIcon,title:'Unit'})))
                .concat(entranceMarker.map(l => Object.assign({}, {latLng: l, icon: entranceIcon,title:'Entrance'})))
                .concat(stairMarker.map(l => Object.assign({}, {latLng: l, icon: stairsIcon,title:'Stairs'})))
                .concat(elevatorMarker.map(l => Object.assign({}, {latLng: l, icon: elevatorIcon,title:'Elevator'})))
                .concat(parkingMarker.map(l => Object.assign({}, {latLng: l, icon: parkingIcon, title:'Parking'})));

            //no waypoints, use the default location
            if(tempLatLng.length == 0){
                tempLatLng.push({latLng: {lat: response.data.geocode.latitude, lng: response.data.geocode.longitude}, icon: unitIcon})
            }

            await setExtraInfo(response.data?.additionalInformation)
            await setLatLng(tempLatLng);
            await setGeocoded(response.data.geocode)
            await setAddressComponents(response.data?.addressComponents)
            await setOriginalInput(response.data.originalInput)
            await setShowResults(true)
            await setLoading(false)
            if(response.data?.flaggedIssues && response.data?.flaggedIssues.length > 0){
                await setFlagged(response.data.flaggedIssues)
            }else{
                await setFlagged([])
            }
        }else{
            setLatLng(null)
            setExtraInfo(null)
            setCenter(null)
            setAddressComponents(null)
            setLatLng(null)
            setFlagged([])
            setZoom(16)

            toast.error(response.data && response.data.message ? response.data.message : "Unable to validate" ,{
                duration: 5000
            })
            setError(response.data && response.data.message ? response.data.message : "Unable to validate")
            setShowResults(true)
            setLoading(false)
        }

    }

    const clearForm = () => {
        setLatLng(null)
        setExtraInfo(null)
        setCenter(null)
        setAddressComponents(null)
        setLatLng(null)
        setZoom(16)
        setShowResults(false)
        setGeocoded(null)
        setError(null)
        setLoading(false)
        setOriginalInput(null)
        setFlagged([])
    }


    const updateAddress = (datatype) => ((event) => {
        let data = {}
        data[datatype] = event.target.value
        setAddress(a => Object.assign({}, a, data))
        setGeocoded(null)
        setValidation(null)
    })

    const goToLandingPage = () => {
        navigate(`/`)
    }

    const getSource = (addressComponents, flagged) => {

        if(!!flagged && flagged.length > 0 && !addressComponents.validator ){
            return(
                <>
                    <span style = {{ color: 'pink', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> Retrieved from cache with issues</span>
                </>)
        }


        return(
        <>
        { addressComponents.isCorrected && addressComponents.isCorrected == true ?
            <span style = {{ color: 'red', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> Corrected </span> :
            <span style = {{ color: 'green', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> Validated </span>
        }
        by {addressComponents.validator}
        </>)
    }

    
    return (
    <Grid container style={{ height: 'calc(100wh - 65px)' }}>
        <Container maxWidth='xl' style={{paddingLeft: 0, paddingBottom: 50,  display: 'flex', justifyContent: 'space-between'}}>
            <Button variant='outlined' onClick={ ()=>{goToLandingPage()} }>Back to Main</Button>
        </Container>
    <Grid container spacing={1}>

            <Grid item xs={5} style={{minHeight: 540}} spacing={10}>

                <Grid container spacing={1}>
                    {loading ? <CircularProgress color="primary" size={20} /> : ''}
                    { (!showResults || error) &&
                    <Grid container spacing={1}>

                        <Grid item xs={12}>
                            <H4>Street</H4>
                            <OutlinedInput onChange={ updateAddress('street1')} fullWidth style={{height: 40}} value={address ? address.street1 : ''} />
                        </Grid>
                        <Grid item xs={12}>
                            <H4>Street2</H4>
                            <OutlinedInput onChange={ updateAddress('street2') } fullWidth style={{height: 40}} value={address ? address.street2 : ''} />
                        </Grid>
                        <Grid item xs={12}>
                            <H4>City</H4>
                            <OutlinedInput onChange={ updateAddress('city') } fullWidth style={{height: 40}} value={address ? address.city : ''} />
                        </Grid>
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={5}><H4>State</H4> </Grid>
                            <Grid item xs={7}><H4>Zipcode</H4> </Grid>
                            <Grid item xs={5}>
                                <OutlinedInput onChange={ updateAddress('state') } fullWidth style={{height: 40}} value={address ? address.state : ''} />
                            </Grid>
                            <Grid item xs={7}>
                                <OutlinedInput onChange={ updateAddress('zipcode') } fullWidth style={{height: 40}} value={address ? address.zipcode : ''} />
                            </Grid>
                        </Grid>
                        {
                            error &&
                            <Grid item xs={12}>
                                <H4 style={{color: 'red'}}>{error}</H4>
                            </Grid>
                        }
                        <Grid item container xs={12} spacing={1}>
                            <Grid item style={{display: 'flex', justifyContent: 'space-between', height: 40, marginTop: 10, padding: 0}}>
                                <Button style={{fontSize: 11}} size={'small'} disabled={loading} onClick={validate}>
                                    { error ? 'Validate Another' : 'Validate' }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                 }
                <Grid item xs={12}>
                    {addressComponents &&
                        <ColorBox color={'success'}>
                        <Grid container>
                            <Grid item xs={7} spacing={1}>
                                <H4>Status:</H4>
                                <Grid item xs={8}>
                                    {getSource(addressComponents,flagged)}
                                </Grid>

                            </Grid>

                            <Grid item xs={12} >
                                <H4>Street Line 1:</H4>
                                <Diff str={addressComponents.streetLine} orig={ !!addressComponents?.componentNotes?.street1 ? originalInput?.street1 : null} />
                            </Grid>
                            <Grid item xs={12} >
                                <H4>Street Line 2:</H4>
                                <Diff str={addressComponents.secondaryAddressLine} orig={ !!addressComponents?.componentNotes?.secondaryAddressUnitDesignator ? originalInput?.street2 : null} />
                            </Grid>
                            <Grid item xs={12}>
                                <H4>City:</H4>
                                <Diff str={addressComponents.city} orig={ !!addressComponents?.componentNotes?.city ? originalInput?.city : null} />
                            </Grid>
                            <Grid item xs={12}>
                                <H4>State:</H4>
                                <Diff str={addressComponents.state} orig={ !!addressComponents?.componentNotes?.state ? originalInput?.state : null} />
                            </Grid>
                            <Grid item xs={12}>
                                <H4>Zip:</H4>
                                <Diff str={`${addressComponents.zip}-${addressComponents.zip4}` } orig={ (addressComponents?.componentNotes?.zip || addressComponents?.componentNotes?.zip4) ? originalInput?.zipcode : null} />
                            </Grid>
                            <Grid item xs={12}>
                                <H4>Free Form address:</H4>
                                <Typography style={{fontSize: '0.9em'}}><Copiable txt={   addressComponents.freeFormAddress } /></Typography>
                            </Grid>
                        </Grid>
                        </ColorBox>
                    }
                </Grid>
                { extraInfo &&
                    <ColorBox color={''}>
                    <Grid item xs={12} container spacing={1}>
                        <H4>Extra Info:</H4>
                        <Grid container>
                        <Grid item xs={5}><H4>is_multi_dwelling_unit</H4> <span style = {{ color: 'green', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> {extraInfo && extraInfo.is_multi_dwelling_unit === true ? 'true' : 'false'} </span> </Grid>
                        <Grid item xs={5}><H4>requires_access_code</H4> <span style = {{ color: 'green', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> {extraInfo && extraInfo.requires_access_code === true ? 'true' : 'false'} </span> </Grid>
                        <Grid item xs={5}><H4>requires_stair_access</H4> <span style = {{ color: 'green', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> {extraInfo && extraInfo.requires_stair_access === true ? 'true' : 'false'} </span> </Grid>
                        <Grid item xs={5}><H4>requires_elevator_access</H4> <span style = {{ color: 'green', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> {extraInfo && extraInfo.requires_elevator_access === true ? 'true' : 'false'} </span>  </Grid>
                        <Grid item xs={5}><H4>access_code</H4> <span style = {{ color: 'green', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> {extraInfo && extraInfo.access_code  ? extraInfo.access_code : ''} </span> </Grid>
                        <Grid item xs={5}><H4>is_military_base</H4> <span style = {{ color: 'green', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> {extraInfo && extraInfo.is_military_base === true ? 'true' : 'false'} </span> </Grid>
                        {extraInfo && extraInfo.military_base &&
                            <Grid item xs={5}><H4>Post Secondary School</H4> <span style={{
                                color: 'black',
                                fontFamily: 'AvenirNext-DemiBold',
                                fontSize: '14px'
                            }}> {extraInfo && extraInfo.military_base} </span> </Grid>
                        }
                        <Grid item xs={5}><H4>is_post_secondary_school</H4> <span style = {{ color: 'green', fontFamily: 'AvenirNext-DemiBold',fontSize: '14px'}}> {extraInfo && extraInfo.is_post_secondary_school === true ? 'true' : 'false'} </span> </Grid>
                        {extraInfo && extraInfo.post_secondary_school &&
                            <Grid item xs={5}><H4>Post Secondary School</H4> <span style={{
                                color: 'black',
                                fontFamily: 'AvenirNext-DemiBold',
                                fontSize: '14px'
                            }}> {extraInfo && extraInfo.post_secondary_school} </span> </Grid>
                        }

                        </Grid>
                    </Grid>
                    </ColorBox>
                }
                <Grid item xs={12}>
                    { validation && validation.USPS && <ColorBox style={{marginBottom: 10}} color={validation.USPS === 'VALIDATED' ? 'success' : 'failed'}>
                        <Grid container>
                            <Grid item xs={3}><H5>USPS</H5></Grid>
                            <Grid item xs={9}>{validation.USPS}</Grid>
                        </Grid>
                    </ColorBox>}
                </Grid>
                <Grid item xs={12}>
                    { geocoded &&
                        <ColorBox color={geocoded.status || ''}>
                        <H4>Geocode Result</H4>
                            <Grid container>
                                <Grid item xs={9}>{ geocoded.source }</Grid>
                                {geocoded &&
                                    <Grid item xs={12} >result:
                                        <Copiable txt={`${geocoded.latitude}, ${geocoded.longitude}`} />
                                    </Grid>
                                }
                            </Grid>
                        </ColorBox>}
                </Grid>
                <Grid item xs={12}>
                    { flagged && flagged.length > 0 &&
                        <ColorBox color={'failed'}>
                            <H4>Address was retrieved from cache but it appears to to have some validation issues:</H4>
                            <Grid container>
                                {
                                    flagged.map((flag, i) => {
                                        return <Grid item xs={12} key={i}>{flag}</Grid>
                                    })
                                }
                            </Grid>
                        </ColorBox>}
                </Grid>
                { showResults && !error &&
                    <Grid item container xs={12}>
                        <Container style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: 40,
                            marginTop: 10,
                            padding: 0
                        }}>
                            <Button style={{fontSize: 11}} size={'small'} onClick={() => {
                                clearForm()
                            }}>Validate Another</Button>
                        </Container>
                    </Grid>
                }
            </Grid>
            </Grid>
            <Grid item xs={7}>
                <GeocodingMap center={center} initialZoom={zoom} google_key={`${process.env.REACT_APP_GOOGLE_KEY}`} markers={latLng ? latLng : []} />
            </Grid>
    </Grid>
    </Grid>
        )
}
