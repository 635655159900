import { Paper } from "@material-ui/core"

export const BlueBox = ({style, children}) =>
    <Paper elevation={0} style={{...{backgroundColor: 'rgba(244, 243, 255, 0.58)', padding: 10, paddingTop: 5}, ...style}}>{ children }</Paper>

export const GrayBox = ({style, children}) =>
    <Paper elevation={0} style={{...{backgroundColor: 'rgba(240, 240, 240, 0.58)', padding: 10, paddingTop: 5}, ...style}}>{ children }</Paper>

export const RedBox = ({style, children}) =>
    <Paper elevation={0} style={{...{backgroundColor: 'rgba(255, 243, 244, 0.58)', border: 'solid 1px #f4485e', padding: 10, paddingTop: 5}, ...style}}>{ children }</Paper>

export const ColorBox = ({color, ...options}) => {
    if (!color) return <GrayBox {...options} />
    if (color.toLowerCase() === 'success') return <BlueBox {...options} />
    if (color.toLowerCase() === 'failed') return <RedBox {...options} />
    return <GrayBox {...options} />
}