import React, { Fragment, useState } from 'react'

import HistoryEventJson from '../HistoryEventJson'

const Dot = ({color, onClick, dotLeft}) => <div onClick={() => onClick()} style={{position: 'absolute', top: 11, left: dotLeft, width: 7, height: 7, borderRadius: '50%', cursor: "pointer", backgroundColor: color}}/>

const statusColor = (s) => {
    if (!s) return '#ccc'
    if (['SUCCEEDED', 'GEOCODED', 'DROPOFF_SUCCEEDED', 'RECEIVED_OK'].indexOf(s) >=0 ) return '#77b45c'
    if (['READY'].indexOf(s) >=0 ) return '#f5a623'
    if (['FAILED', 'GEOCODE_FAILED', 'DROPOFF_FAILED'].indexOf(s) >=0 ) return '#f4485e'
    return '#ccc'
}

function HistoryItem({children, status, event, dotLeft = 0}) {
  const [isShowModal, setIsShowModal] = useState(false);

  const handleToggleModal = (value) => {
    setIsShowModal(value)
  }

  return (
    <Fragment>
        <Dot color={statusColor(status)} onClick={() => handleToggleModal(true)} dotLeft={dotLeft} />
        {children}

        {isShowModal && <HistoryEventJson isShowModal={isShowModal} event={event} handleToggleModal={(v) => handleToggleModal(v)} />}
    </Fragment>
  )
}

export default HistoryItem