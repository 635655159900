/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AppContainer from './containers';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import './App.css';

const theme = createTheme({
  typography: {
    fontFamily: [
      'AvenirNext',
      '-apple-system',
      // 'Arial',
      'sans-serif'
    ],
    fontWeightRegular: 'normal',
    fontWeightMedium: 'normal',
    fontWeightLight: 'normal'
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
    <RecoilRoot>
      <Router>
        <AppContainer />
      </Router>
    </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
