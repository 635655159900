import { selectorFamily, useRecoilCallback, atomFamily, selector } from 'recoil';

import { clientRepo } from '../repositories';

const clientsAtom = atomFamily({
    'key': 'client-family-atoms'
})

export const clientsSelector = selectorFamily({
    'key': 'client-family-selector',
    get: (id) => async ({get}) => {
        if (!id) return null
        const client = get(clientsAtom(id))
        if (client) return client;
        return await clientRepo.get(id)
    }
})

export const allClientsSelector = selector({
    'key': 'all-client',
    get: async () => {
      let data = await clientRepo.list();
      data = [...new Map(data.map((client) => ([client.client_id, client]))).values()];
      const clients = data.map((client) => ({ id: client.client_id, company: client.name }));

      return clients;
    }
})

export const useLoadAllClientsCallback = () => {  
    return useRecoilCallback(({snapshot, set, reset}) => async () => {
        const clients = await snapshot.getPromise(allClientsSelector)
        if (clients)
        clients.map(client => {
            set(clientsAtom(client.id), client)
        });
    })
}
