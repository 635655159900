import React from 'react';

import { useRecoilValue } from 'recoil';
import { selectedShipmentState } from '../../states/shipment';
import ShipmentDetail from '../../components/ShipmentDetail';
import { Paper, LinearProgress, Container } from '@material-ui/core';
import { H2, H3 } from '../../components/Heading'

import withStyles from "@material-ui/core/styles/withStyles";
import AddressContainer from '../Address';
import CustomerProfileContainer from '../CustomerProfile';
import ClientLogoContainer from '../ClientLogo';

const styles = () => ({
    error: { border: "solid 1px #f4485e", borderLeft: 'solid 8px #f4485e', padding: 10, borderRadius: 5 },
    success: { border: "solid 2px #75c31e", borderLeft: 'solid 8px #75c31e', padding: 10, borderRadius: 5 },
    failed: { border: "solid 2px #4a4a4a", borderLeft: 'solid 8px #4a4a4a', padding: 10, borderRadius: 5 },
    normal: { padding: 15 },
    item: {
        fontSize: '15px',
        padding: '2px',
        color: '#5a5a5a',
    },
    header: {
        fontSize: '1.2em',
        fontWeight: 500
    }
});

function ShipmentDetailContainer({classes}) {
    const shipment = useRecoilValue(selectedShipmentState)
    const className = !shipment || shipment.status === 'GEOCODE_FAILED' || shipment.status === 'CANCELLED_BEFORE_PICKUP' ? classes.error : classes.success
    const { dropoff_address, client_id } = shipment || {}
    return <Paper className={className}>
        <Container style={{display: 'flex', padding: 0}}>
            <div style={{flex: 1}}><H2>Shipment Info</H2></div>
            <div style={{marginTop: -10}}><ClientLogoContainer id={client_id} /></div>
        </Container>

        { shipment && <ShipmentDetail shipment={shipment} /> }

        <H3>Customer Profile Info</H3>
        <div style={{marginBottom: 10}}>
            <React.Suspense fallback={<LinearProgress />}>
                <CustomerProfileContainer originalAddress={dropoff_address} />
            </React.Suspense>
        </div>
        <React.Suspense fallback={<LinearProgress />}>
            {shipment && <AddressContainer shipment={shipment} />}
        </React.Suspense>
    </Paper>
}

export default withStyles(styles)(ShipmentDetailContainer)
