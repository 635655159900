import { create_api } from "./base";

export default class CustomerProfileRepo {
    baseUrl = "/customer-profile";
  
    constructor() {
      this.api = create_api(process.env.REACT_APP_API_ROOT);
    }
  
    get = (id) => this.api.get(`${this.baseUrl}/${id}`).then(r => r.data)
    correctAddress = (id, shipmentId, address, addressOnly) => {
        let correctAddressUrl = `${this.baseUrl}/${id}/correct-address`
        if (!!addressOnly) {
            this.api.post(correctAddressUrl, address, { params: { address_only: true, shipment_id: shipmentId } }).then(r => r.data)
        } else {
            this.api.post(correctAddressUrl, address, { params: { shipment_id: shipmentId } }).then(r => r.data)
        }
    }
    deliveryHistory = (id) => this.api.get(`${this.baseUrl}/${id}/delivery-history`).then(r => r.data)
    update = (profile) => this.api.put(`${this.baseUrl}/${profile.id}`, profile).then(r => r.data)
}
