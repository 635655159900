import { Wrapper } from "@googlemaps/react-wrapper";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import React, { useEffect } from 'react';
import { createCustomEqual } from "fast-equals";
import { useSetRecoilState } from "recoil";
import { dropPinsState } from "../../states/map";

const render = (status) => {
    return <h1>{status}</h1>;
};

const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a, b) => {
        if (
            isLatLngLiteral(a) ||
            a instanceof window.google.maps.LatLng ||
            isLatLngLiteral(b) ||
            b instanceof window.google.maps.LatLng
        ) {
            return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
        }
        return deepEqual(a, b);
    }
)

function useDeepCompareMemoize(value) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(
    callback,
    dependencies
) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const Map = ({
    onClick,
    onIdle,
    children,
    style,
    ...options
}) => {
    const ref = React.useRef(null);
    const [map, setMap] = React.useState();
    const [infoWindow, setInfoWindo] = React.useState()
    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
        if (!infoWindow) {
            setInfoWindo(new window.google.maps.InfoWindow())
        }
    }, [ref, map]);

    React.useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions({ ...options, mapTypeId: window.google.maps.MapTypeId.ROADMAP });
        }
    }, [map, options]);
    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map, infoWindow });
                }
            })}
        </>
    );
}

const Marker = (options) => {
    const [marker, setMarker] = React.useState();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
            if (options.infoWindow)
                marker.addListener("click", () => {
                    options.infoWindow.close();
                    options.infoWindow.setContent(marker.getTitle());
                    options.infoWindow.open(marker.getMap(), marker);
                });
        }
    }, [marker, options]);

    return null;
};

export default function GeocodingMap({ google_key, markers, center, initialZoom }) {
    // [START maps_react_map_component_app_state]
    const setClicks = useSetRecoilState(dropPinsState);
    const [zoom, setZoom] = React.useState(20); // initial zoom
    const [mapCenter, setCenter] = React.useState({
        lat: 37,
        lng: -122,
    });

    useEffect(() => {
        if (center) {
            setCenter(center)
        }
        if (initialZoom) {
            setZoom(initialZoom)
        }
    }, [center, initialZoom])

    const onClick = (e) => {
        // avoid directly mutating state
        const l = e.latLng
        setClicks([{lat: l.lat(), lng: l.lng()}]);
    };

    const onIdle = (m) => {
        setZoom(m.getZoom());
        setCenter(m.getCenter().toJSON());
    };
    const styles = [
        {
            'featureType': 'transit',
            'stylers': [{
                'visibility': 'off'
            }]
        },
        {
            "featureType": "administrative.land_parcel",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        }
    ]

    return (
        <div style={{ display: "flex", position: "relative", height: "100%" }}>
            <Wrapper apiKey={google_key} render={render}>
                <Map
                    center={mapCenter}
                    onClick={onClick}
                    onIdle={onIdle}
                    zoom={zoom}
                    style={{ flexGrow: "1", height: "100%" }}
                    styles={styles}
                >
                    {markers && markers.filter(m => m.latLng && m.latLng.lat && m.latLng.lng).map((m, i) => (
                        <Marker key={i} position={m.latLng} icon={m.icon} title={ m.title ? m.title : `${m.latLng.lat.toFixed(5)},${m.latLng.lng.toFixed(5)}`} />
                    ))}
                </Map>
            </Wrapper>
            {/* Basic form for controlling center and zoom of map. */}
        </div>
    );
}