import { atom, selector, useRecoilCallback } from 'recoil';

import { useParams, useNavigate, useLocation } from 'react-router';

import { incidentRepo, shipmentRepo } from '../repositories';
import { clientsSelector } from './client';
import { refPinsState } from './map';

import { useSelectShipmentCallback } from './shipment'

export const incidentFilterState = atom({
    key: 'incident-filter-state',
    default: {
        status: 'CREATED'
    }
})

export const incidentTagsState = atom({
    key: 'incident-tags-state',
    default: {
        dp: [],
        rg: []
    }
})

const constructListPath = (filter, pageFilter, tagGroups) => {
    const queryNames = {'status': 'CREATED', 'client': undefined, 'date': undefined,}
    const tags = tagGroups ? Object.values(tagGroups).flat() : []
    const q = Object.entries(queryNames).map(entry => {
        const [key, initial] = entry;
        const value = filter[key]
        return value && value !== initial ? `${key}=${value}` : null
    }).filter(k => k)
        .concat([`tags=${tags.join(',')}`])
        .join("&")
    const {type} = filter || {}
    const {page} = pageFilter || {}
    return `/incident/${(type || 'GEOCODE-FAILED').toLowerCase()}/page/${parseInt(page || '1')}?${q}`
}

const constructDetailPath = (id, filter, pageFilter, tagGroups) => {
    const queryNames = {'status': 'CREATED', 'client': undefined, 'date': undefined}
    const {page} = pageFilter || {}
    const tags = tagGroups ? Object.values(tagGroups).flat() : []
    let q = Object.entries(queryNames).map(entry => {
        const [key, initial] = entry;
        const value = filter[key]
        return value && value !== initial ? `${key}=${value}` : null
    }).filter(k => k)
        .concat([`page=${(page || 1)}`])
        .concat([`tags=${tags.join(',')}`])

    const {type} = filter || {}
    return `/incident/${(type || 'GEOCODE-FAILED').toLowerCase()}/${id}?${q.join('&')}`
}

export const useUpdateFilterCallback = () => {
    const navigate = useNavigate()
    return useRecoilCallback(({snapshot, set, reset}) => async (update) => {
        const filter = await snapshot.getPromise(incidentFilterState)
        const tagGroups = await snapshot.getPromise(incidentTagsState)
        const updated = {...filter, ...update}
        // refresh url
        set(incidentFilterState, updated)
        set(currentPageState, {page: 1, requested: Date.now()})
        navigate(constructListPath(updated, {page:1}, tagGroups))
    })
}

export const useUpdateTagCallback = () => {
    const navigate = useNavigate()
    return useRecoilCallback(({snapshot, set, reset}) => async (update) => {
        const filter = await snapshot.getPromise(incidentFilterState)
        const tagGroups = await snapshot.getPromise(incidentTagsState)
        const updated = {...tagGroups, ...update}
        // refresh url
        set(incidentTagsState, updated)
        set(currentPageState, {page: 1, requested: Date.now()})
        navigate(constructListPath(filter, {page:1}, updated))
    })
}

export const useGotoListPageCallback = () => {
    const navigate = useNavigate()
    return useRecoilCallback(({snapshot, set, reset}) => async () => {
        const filter = await snapshot.getPromise(incidentFilterState)
        const page = await snapshot.getPromise(currentPageState)
        const tagGroups = await snapshot.getPromise(incidentTagsState)
        navigate(constructListPath(filter, page, tagGroups))
    })
}

export const useGotoDetailPageCallback = () => {
    const navigate = useNavigate()
    return useRecoilCallback(({snapshot, set, reset}) => async (id) => {
        const filter = await snapshot.getPromise(incidentFilterState)
        const page = await snapshot.getPromise(currentPageState)
        const tagGroups = await snapshot.getPromise(incidentTagsState)
        navigate(constructDetailPath(id, filter, page, tagGroups))
    })
}

export const useLoadFilterCallback = () => {
    const params = useParams()
    const location = useLocation()

    return useRecoilCallback(({snapshot, set, reset}) => async (update) => {
        const filter = {
            status: 'CREATED',
            type: 'GEOCODE-FAILED',
            date:  undefined,
        }
        const { type, page } = params || {}
        let updated = { type: type ? type.toUpperCase() : 'GEOCODED-FAILED'}
        const queryParams = new URLSearchParams(location.search || '');
        ['status', 'client', 'date'].forEach(k => {
            const v = queryParams.get(k)
            if (v) {
                updated[k] = v
            }
        })
        const currentPage = parseInt(queryParams.get('page') || page || '1')
        let tagGroups = {}
        const tags = queryParams.get('tags') ? queryParams.get('tags').split(',') : []
        if (tags.indexOf('DSP') >= 0) {
            tagGroups['dp'] = ['DSP']
        } else if (tags.indexOf('DP') >= 0) {
            tagGroups['dp'] = ['DP']
        }
        for (const tag of tags) {
            if (tag.indexOf('CL_') == 0) {
                const existing = tagGroups['client'] || []
                tagGroups['client'] = existing.concat([tag])
            }
            if (tag.indexOf('RG_') == 0) {
                const existing = tagGroups['region'] || []
                tagGroups['region'] = existing.concat([tag])
            }
            if (tag.indexOf('Search_') == 0) {
                const existing = tagGroups['search'] || []
                tagGroups['search'] = existing.concat([tag])
            }
        }

        set(incidentTagsState, tagGroups)
        set(incidentFilterState, { ...filter, ...updated })
        set(currentPageState, { page: currentPage, requested: Date.now() })
    })
}

export const useUpdatePageCallback = () => {
    const navigate = useNavigate()
    return useRecoilCallback(({snapshot, set, reset}) => async (page) => {
        const filter = await snapshot.getPromise(incidentFilterState)
        // refresh url
        set(currentPageState, {page: page, requested: Date.now()})
        navigate(constructListPath(filter,{page:page}))
    })
}

export const currentPageState = atom({
    key: 'current-incident-page',
    default: {page: 1, requested: 0},
})

export const selectedIncidentState = atom({
    key: 'selected-incident-state',
    default: null,
})

export const selectedIncidentIdState = atom({
    key: 'selected-incident-id-state',
    default: null,
})

export const incidentDetailState = atom({
    key: 'incident-detail-state',
    default: null
})

export const incidentListSelector = selector({
    key: 'incident-list-select',
    get: async ({get}) => {
        const { page } = get(currentPageState) || {}
        const { status, type, date } = get(incidentFilterState)
        console.log('date>>>>', date);
        const tagGroups = get(incidentTagsState)
        const tags = Object.values(tagGroups).flat()
        if (!type) return {
            total: 0,
            items: []
        }
        const response = await incidentRepo.list((page || 1), type, [status || 'CREATED'], tags,null, date);
        let data = response.data
        if (tagGroups.search && tagGroups.search.length > 0) {
            let filter = {
                from: page -1 || 0,
                size: 499,
                q: tagGroups.search[0].split('_')[1].trim(),
                filters: {has_incident: true},
                sorts: ['-dropoff_earliest_ts'],
            }
            tags.splice(tags.findIndex(t => t == tagGroups.search), 1);
            const searchRes = await incidentRepo.search(filter, type, [status || 'CREATED'], tags,null, date);
            data.items = data.items.concat(searchRes.data.items)
        }
        const shipment_ids = data.items.map(i => i.object).map(s => s.split('_')[1]).map(s => parseInt(s))
        const shipments = await shipmentRepo.pull(shipment_ids)
        let shipment_map = {}
        shipments.forEach(element => {
            shipment_map[element.id] = element
        });
        return Object.assign({}, data, { items: data.items.map(item => Object.assign(item, {shipment: shipment_map[parseInt(item.object.split('_')[1])] })) })
    },
})

export const useResolveCallback = () => {
  return useRecoilCallback(({snapshot, set, reset}) => async (status, resolution, statusType) => {
    const incident = await snapshot.getPromise(incidentDetailState);

    return incidentRepo.resolve(incident, status, resolution, statusType).then(() => {
      reset(selectedIncidentState)
      reset(selectedIncidentIdState)
      reset(incidentDetailState)
    });
  });
}

export const useLoadIncidentCallback = () => {
    const selectShipment = useSelectShipmentCallback()
    return useRecoilCallback(({snapshot, set, reset}) => async (id) => {
        set(selectedIncidentIdState, id)
        if (!id) {
            reset(incidentDetailState)
            // reset(selectedShipmentIdState)
            selectShipment(null)
            return
        }
        const resp = await incidentRepo.get(id)
        const incident = resp.data
        if (incident && incident.ref && incident.ref.split('_')[0] === 'CL') {
            snapshot.getLoadable(clientsSelector(parseInt(incident.ref.split('_')[1])));
        }
        set(incidentDetailState, incident)
        if (incident && incident.type === 'WRONG-DROPOFF-LOCATION') {
            const { attributes } = incident || {}
            const { actual_lat, actual_lng } = attributes || {}
            if (actual_lat && actual_lng) {
                set(refPinsState, [{lat: parseFloat(actual_lat), lng: parseFloat(actual_lng)}])
            } else {
                set(refPinsState, [])
            }
        } else {
            set(refPinsState, [])
        }
        const shipment_id = incident ? parseInt(incident.object.split('_')[1]) : null
        selectShipment(shipment_id)
        // set(selectedShipmentIdState, shipment_id)
    })
}

export const useMovePageCallback = () => {
    return useRecoilCallback(({snapshot, set, reset}) => async (inc) => {
        const currentPage = await snapshot.getPromise(currentPageState)
        const { page } = currentPage || { page: 1}
        set(currentPageState, { 'page': page + inc, requested: Date.now()})
    })
}
