import { create_api } from "./base";

export default class NoteRepo {
    baseUrl = "/notes";
  
    constructor() {
      this.api = create_api(process.env.REACT_APP_API_ROOT);
    }
  
    get = (id) => this.api.get(`${this.baseUrl}/${id}`).then(r => r.status === 200 ? r.data : [])
    add = (id, content) => this.api.post(`${this.baseUrl}/${id}`, content).then(r => r.data)
}