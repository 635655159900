import React, { useEffect } from 'react';

import { Container, Paper } from "@material-ui/core";
import { useParams,  useNavigate } from "react-router-dom";

import { useSelectShipmentCallback } from '../../states/shipment';

import Button from "@material-ui/core/Button";
import ShipmentProfile from '../ShipmentProfile';


export default function ShipmentGeocodingContainer() {
    const params = useParams()
    let navigate = useNavigate();
    const selectShipment = useSelectShipmentCallback()

    useEffect(() => {
        selectShipment(params.id)
    }, [params.id])

    const goBack = () => {
        navigate(`/incident`, { replace: true })
    }

    return (
        <Container maxWidth='xl' style={{ height: 'calc(100% - 65px)', paddingTop: 10 }}>
            <Paper elevation={2}>
                <Container maxWidth='xl' style={{padding: 15, display: 'flex', justifyContent: 'space-between'}}>
                    <Button variant='outlined' onClick={ goBack }>Back</Button>
                </Container>
                <React.Suspense fallback={<div>Loading...</div>}>
                <Container maxWidth='xl' style={{padding: 15}}>
                    <ShipmentProfile />
                </Container>
                </React.Suspense>
            </Paper>
        </Container>
    );
}

