import React from 'react';

import { Typography } from "@material-ui/core";


export function H5({children}) {
    return <Typography style={{fontSize: 15, color: '#4a4a4a', fontWeight: 400}}>
        { children }
    </Typography>
}

export function H4({children, style}) {
    return <Typography style={{fontSize: 15, color: '#4e4b70', fontWeight: 'bold', paddingTop: 10, ...style}}>
        { children }
    </Typography>
}

export function H3({children, style}) {
    return <Typography style={{fontSize: 18, color: '#4a4a4a', ...style}}>
        { children }
    </Typography>
}

export function H2({children, style}) {
    return <Typography style={{fontSize: 21, color: '#4a4a4a', fontWeight: 'bold', paddingBottom: 10, ...style}}>
        { children }
    </Typography>
}

export function Label({children}) {
    return <span style={{fontSize: 15, color: '#5e5b78'}}>
        { children }
    </span>
}
