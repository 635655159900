import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { Grid, Paper, Typography, Tab, Tabs, Box, LinearProgress, Container, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useRecoilValue } from 'recoil';

import AddressContainer from '../Address';
import CustomerProfileContainer from '../CustomerProfile';
import AddressLocator from '../AddressLocator'
import DeliverySearchContainer from '../DeliverySearch';
import DeliveryHistory from '../DeliveryHistory';
import { H2, H4 } from '../../components/Heading'
import { selectedProfileState, useSelectProfileCallback } from '../../states/profile'
import { BlueBox } from '../../components/Box';

const styles = () => ({
    error: { border: "solid 1px #f4485e", borderLeft: 'solid 8px #f4485e', padding: 10, marginBottom: 6, borderRadius: 5 },
    success: { border: "solid 2px #75c31e", borderLeft: 'solid 8px #75c31e', padding: 10, marginBottom: 6, borderRadius: 5 },
    failed: { border: "solid 2px #4a4a4a", borderLeft: 'solid 8px #4a4a4a', padding: 10, marginBottom: 6, borderRadius: 5 },
    normal: { padding: 15, width: '100%' },
    item: {
        fontSize: '15px',
        padding: '2px',
        color: '#5a5a5a',
    }
});

function CustomerProfileInfo() {
    const profile = useRecoilValue(selectedProfileState)
    const { customer } = profile || {}
    return <>
        <Container style={{ display: 'flex', padding: 0 }}>
            <div style={{ flex: 1 }}><H2>Customer Profile Info</H2></div>
        </Container>
        {customer && <BlueBox>
            <Grid container>
                <Grid item xs={7}>
                    <H4>Customer</H4>
                    <Typography>{customer.name}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <H4>Phone</H4>
                    <Typography>{customer.phone_number}</Typography>
                </Grid>
            </Grid>
        </BlueBox>}

        <div style={{ marginBottom: 10 }}>
            <React.Suspense fallback={<LinearProgress />}>
                <CustomerProfileContainer />
            </React.Suspense>
        </div>
        <React.Suspense fallback={<LinearProgress />}>
            <AddressContainer />
        </React.Suspense>
    </>
}

function CustomerProfileScreen({ classes }) {
    const params = useParams()
    const [searchTab, setSearchTab] = useState(0)
    const selectProfile = useSelectProfileCallback()
    let navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setSearchTab(newValue);
    };

    useEffect(() => {
        selectProfile(params.id)
    }, [])

    const goBack = () => {
        navigate(`/incident`, { replace: true })
    }

    return (<Container maxWidth='xl' style={{ height: 'calc(100% - 65px)', paddingTop: 10 }}>
        <Paper elevation={2}>
            <Container maxWidth='xl' style={{ padding: 15, display: 'flex', justifyContent: 'space-between' }}>
                <Button variant='outlined' onClick={goBack}>Back</Button>
            </Container>
            <React.Suspense fallback={<div>Loading...</div>}>
                <Container maxWidth='xl' style={{ padding: 15 }}>
                    <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 200px)' }}>
                        <Grid item container xs={4}>
                            <Paper className={classes.success}>
                                <React.Suspense fallback={<LinearProgress />}>
                                    <CustomerProfileInfo />
                                </React.Suspense>
                            </Paper>
                        </Grid>
                        <Grid item container xs={4}>
                            <Paper elevation={2} className={classes.normal}>
                                <Container style={{ padding: 0, height: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 10 }}>
                                        <Tabs value={searchTab} onChange={handleChange} aria-label="Delivery History Tab">
                                            <Tab label="Address History" id='tab-address-history' />
                                            <Tab label="Custom Search" id='tab-custom-search' />
                                        </Tabs>
                                    </Box>
                                    <div
                                        role="tabpanel"
                                        hidden={searchTab !== 0}
                                        id={`tabpanel-address-history`}
                                        aria-labelledby={`tab-address-history`}
                                        style={{ height: 'calc(100% - 70px)' }}
                                    >
                                        {searchTab === 0 && (
                                            <React.Suspense fallback={<LinearProgress />}>
                                                <DeliveryHistory />
                                            </React.Suspense>
                                        )}
                                    </div>
                                    <div
                                        role="tabpanel"
                                        hidden={searchTab !== 1}
                                        id={`tabpanel-address-history`}
                                        aria-labelledby={`tab-address-history`}
                                        style={{ height: 'calc(100% - 70px)', maxHeight: 'calc(100% - 70px)' }}
                                    >
                                        {searchTab === 1 && (
                                            <React.Suspense fallback={<LinearProgress />}>
                                                <DeliverySearchContainer />
                                            </React.Suspense>
                                        )}
                                    </div>
                                </Container>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <React.Suspense fallback={<LinearProgress />}>
                                <AddressLocator />
                            </React.Suspense>
                        </Grid>
                    </Grid>                
                </Container>
            </React.Suspense>
        </Paper>
    </Container>
    )
}

export default withStyles(styles)(CustomerProfileScreen);
