import { set } from 'lodash';
import { atom, useRecoilCallback } from 'recoil';

import { customerProfileRepo } from '../repositories';
import { useLoadAddressCallback } from './address'
import { useReloadShipmentCallback } from './shipment';

export const selectedProfileIdState = atom({
    key: 'selected-profile-id-state'
})

export const selectedProfileState = atom({
    key: 'selected-profile-state'
})

export const forceReloadAddress = atom({
    key: 'force-reload-address-state'
})

export const profileLoadingState = atom({
    key: 'profile-loading-state',
    default: false
});

export const useReloadProfileCallback = () => {
    const loadAddress = useLoadAddressCallback()
    return useRecoilCallback(({snapshot, set, reset}) => async () => {
        const id = await snapshot.getPromise(selectedProfileIdState)
        const profile = id ? await customerProfileRepo.get(id) : null
        set(selectedProfileState, profile)
        loadAddress(profile ? profile.deliverable_address_id : null)
    })
}

export const useSelectProfileCallback = () => {
    const loadAddress = useLoadAddressCallback()
    return useRecoilCallback(({snapshot, set, reset}) => async (id) => {
        set(selectedProfileIdState, id)
        const profile = id ? await customerProfileRepo.get(id) : null
        set(selectedProfileState, profile)
        loadAddress(profile ? profile.deliverable_address_id : null)
    })
}


export const useCorrectAddressCallback = () => {
    const reloadShipment = useReloadShipmentCallback()

    return useRecoilCallback(({snapshot, set, reset}) => async (addr, shipmentId, addressOnly) => {
        set(profileLoadingState, true)
        const profile = await snapshot.getPromise(selectedProfileState)
        if (!profile) return
        await customerProfileRepo.correctAddress(profile.id, shipmentId, addr, addressOnly)
        await reloadShipment()
        set(profileLoadingState, false)
    })
}

export const useEditInstructionsCallback = () => {
    return useRecoilCallback(({snapshot, set}) => async (instructions, shipmentId) => {
        const profile = await snapshot.getPromise(selectedProfileState)
        if (!profile) return
        const updated = {...profile, instructions, shipment_id: shipmentId}
        const reloaded = await customerProfileRepo.update(updated)
        set(selectedProfileState, updated)
    })
}
