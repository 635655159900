import React from "react";

import { useSetRecoilState } from "recoil";
import Copiable from "../../components/Copiable";
import { hoveredPinsState, mapCenterState } from '../../states/map'
import DirectionsIcon from '@material-ui/icons/Directions';

export default function GpsLocation({lat,lng, size, google, style, hideNumber, children}) {
    const setPin = useSetRecoilState(hoveredPinsState)
    const setCenter = useSetRecoilState(mapCenterState)
    if (!lat) return <></>
    const fontSize = size === 'sm' ? 12 : size === 'lg' ? 16 : 14
    const margin = size === 'sm' ? 28 : size === 'lg' ? 50 : 40
    return <span onMouseOver={() => { setPin([{lat: parseFloat(lat), lng: parseFloat(lng)}])} }
                onMouseOut = {() => { setPin([])}}
                onClick={() => { setCenter({lat: parseFloat(lat), lng: parseFloat(lng)}); navigator.clipboard.writeText(`${parseFloat(lat).toFixed(5)},${parseFloat(lng).toFixed(5)}`) } }
                style={{display: 'inline-block', cursor: 'pointer', position: 'relative', fontSize: fontSize, paddingLeft: 0, border: 'solid 1px #e8e8e8', margin: 0, borderRadius: 5, backgroundColor: '#fff', ...style}}>
        <div style={{position: 'absolute', pointerEvents: 'none', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, left: 0, top: 0, bottom: 0, backgroundColor: '#f8f8f8', padding: 3, color: '#5a5a5a' }}>
            <Copiable txt={`${parseFloat(lat).toFixed(4)},${parseFloat(lng).toFixed(4)}`}>GPS</Copiable>
        </div>
        <span style={{pointerEvents: 'none', marginLeft: margin}}>
            {!hideNumber && <Copiable txt={`${parseFloat(lat).toFixed(4)},${parseFloat(lng).toFixed(4)}`} /> }
        </span>
        { google && <a href={`https://maps.google.com?q=${lat},${lng}`} rel="noreferrer" target="_blank"><DirectionsIcon style={{marginBottom: -5, marginLeft: 3}} fontSize='small' color='disabled' /></a>}
        { children }
    </span>

}