import React from "react";
import { useRecoilValue } from "recoil";
import { clientsSelector } from "../../states/client";

export function ClientLogo({id, size}) {
    const client = useRecoilValue(clientsSelector(id))
    return client && client.logo_url ?
        <img alt={client.company} style={{maxWidth: size || 48, maxHeight: size || 48}} title={client.company} src={client.logo_url} />
        : client && client.company ? <span>{ client.company }</span>
        : <span>{ id }</span>

}

export default function ClientLogoContainer({id, size}) {
    return <React.Suspense fallback={<span>{id}</span>}>
        <ClientLogo id={id} size={size} />
    </React.Suspense>
}