import { atom, useRecoilCallback, selector } from 'recoil';

import { authRepo } from '../repositories';

export const userState = atom({
  key: 'user',
  default: null,
});

export const isDevSelector = selector({
  key: 'is-dev-selector',
  get: ({get}) => {
    const user = get(userState)
    return user && user.user && user.user.scopes && user.user.scopes.indexOf('dev') >= 0
  }
})

export const useAuthentication = () => {  
  return useRecoilCallback(({snapshot, set, reset}) => async () => {
    authRepo.getUser().then((r) => {
      set(userState, r.ok ? r.data : null)
      if (!r.ok) window.location = `${process.env.REACT_APP_OAUTH_URL}/login?next=${encodeURI(window.location.href)}`
    })
  })
}
