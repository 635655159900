import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { AddPhotoAlternateSharp, Close } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { H4 } from '../../components/Heading';
import { addressRepo } from '../../repositories';
import { verifiedPhotoState } from '../../states/address';
import './style.css';
import {selectedShipmentInfoState} from "../../states/shipment";
import produce from "immer";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const modalPreviewImage = {
  p: 0,
  width: 800,
  bgcolor: 'transparent'
};

function Verify(props) {
  const {address} = props
  const [info, setInfo] = useRecoilState(selectedShipmentInfoState)
  const [verifiedPhotos, setVerifiedPhotos] = useRecoilState(verifiedPhotoState)
  const [imageUpload, setImageUpload] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)

  const [openPreview, setOpenPreview] = useState(false);
  const handleClosePreview = () => setOpenPreview(false);
  const [previewUrl, setPreviewUrl] = useState()
  const [openRemoveImage, setOpenRemoveImage] = React.useState(false);
  const [selectedPhoto, setSelectedPhoto] = React.useState(null);
  const handleClickOpenRemoveImage = (obj) => {
    setOpenRemoveImage(true)
    setSelectedPhoto(obj)
  };
  const handleCloseOpenRemoveImage = () => {
    setOpenRemoveImage(false)
    setSelectedPhoto(null)
  };

  const ref = useRef();
  
  useEffect(() => {
    setVerifiedPhotos(address && address.photos_verified ? address.photos_verified: [])
  }, [])

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setImageUpload(null)
    setUploadSuccess(false)
    setOpen(true)
  };
  const handleClose = () => {
    if (isUploading)
      return
    setOpen(false)
  };

  const onChangeImage = (e) => {
    setUploadSuccess(false)
    setIsUploading(false)
    if (e.target.files && e.target.files[0]) {
      setImageUpload(e.target.files[0])
    }
  }

  const uploadImage = () => {
    setIsUploading(true)
    addressRepo.addVerifyPhoto(address.id, info.shipment.id, imageUpload, (image) => {
      ref.current.value = null;
      setIsUploading(false)
      setUploadSuccess(true)
      setImageUpload(null)
      setVerifiedPhotos([...verifiedPhotos, image])
    })
  }

  const removeImage = () => {
    const cb = () => {
      const removedPhoto = verifiedPhotos.filter(p => p.id === selectedPhoto.id).pop();
      if (removedPhoto.is_pod || !!removedPhoto.pod_id) {
        setInfo(produce(info, draft => {
          draft.pods = draft.pods.map(p => {
            if (p.id === removedPhoto.pod_id) {
              p.status = 'CREATED';
            }
            return p;
          })
        }))
      }
      setVerifiedPhotos(verifiedPhotos.filter(photo => photo.id != selectedPhoto.id))
      setOpenRemoveImage(false)
      setSelectedPhoto(null)
    }

    addressRepo.removeVerifyPhoto(address.id, selectedPhoto.id, info.shipment.id, cb);
  }

  const previewImage = (url) => {
    setOpenPreview(true)
    setPreviewUrl(url)
  }
  
  return (
    <div style={{marginTop: '20px'}}>
      <H4>Verified photos
        <AddPhotoAlternateSharp className='add-icon'  onClick={handleOpen}></AddPhotoAlternateSharp>
      </H4>
      {verifiedPhotos.length == 0 && (
        <Typography style={{fontSize: '0.8em',color: 'rgb(136, 136, 136)', marginTop: '5px'}}>No photo</Typography>
      )}
      <div className='list-items'>
        {verifiedPhotos && verifiedPhotos.map(photo => {
          return <div key={photo.id}>
            <img src ={photo.signed_url} onClick={() => previewImage(photo.signed_url)}/>
            <Close onClick={() => handleClickOpenRemoveImage(photo)}></Close>
            { "uploaded_ts" in photo && "uploaded_by_name" in photo && (
              <p style={{color: "rgb(136, 136, 136)", fontSize: "0.8em"}}>uploaded at {moment(photo.uploaded_ts).format('MM/DD hh:mmA')} by {photo.uploaded_by_name}</p>
            )}
          </div>  
        })}
      </div>
      
      <Modal
        open={openPreview}
        onClose={handleClosePreview}>
        <Box sx={{...style, ...modalPreviewImage}}>
          <Box style={{maxHeight: 600,textAlign: "center",overflowY:"scroll"}}>
            <img src={previewUrl} style={{width:'100%'}}/>
          </Box>
        </Box>
      </Modal>
        
      <Modal
        open={open}
        onClose={handleClose}>
        <Box sx={style}>
          <IconButton onClick={handleClose} className='dialog-close-btn'>
            <Close />
          </IconButton>
          
          <Typography variant="h6" component="h2" style={{marginBottom: '20px'}}>
            Upload Image
          </Typography>
          <input
              accept="image/*"
              id="raised-button-file"
              multiple
              type="file"
              onChange={onChangeImage}
              ref={ref}
            />
            <div style={{marginTop: '20px'}}>
              <Button variant="contained" color="primary" disabled={isUploading == true || imageUpload == null} onClick={uploadImage}>
                Upload
                {isUploading && <span> ... </span>}
              </Button>
            </div>
            {uploadSuccess && (
              <Typography style={{ color:'#2e7d32', marginTop: 20 }}>
                Image uploaded successfully
              </Typography>
            )}
        </Box>
      </Modal>

      <Dialog
        open={openRemoveImage}
        onClose={handleCloseOpenRemoveImage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to remove this photo?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseOpenRemoveImage} style={{fontWeight: 'bold'}} color='primary'>Disagree</Button>
          <Button onClick={removeImage} style={{fontWeight: 'bold'}} color='primary'>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Verify;
