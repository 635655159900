import { create_api } from "./base";

export default class AssignmentRepo {
  baseUrl = "/assignments";
  constructor() {
    this.api = create_api(process.env.REACT_APP_API_ROOT);
  }

  get = (id) => this.api.get(`${this.baseUrl}/${id}`).then(r => r.data)
  getTrackingGps = (id) => this.api.get(`${this.baseUrl}/${id}/locations`).then(r => r.data)
  getEvents = (id) => this.api.get(`/events/AS_${id}/all?rel=true`).then(r => r.data)
}