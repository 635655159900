import React from 'react';

import google from '../../assets/Google_logo.svg'
import apple from '../../assets/Apple_logo_dark_grey.svg';

export function Apple({size}) {
    const height = size === 'sm' ? '14px' : size === 'md' ? '16px' : size === 'lg' ? '40px' : '18px'
    return <img alt="Apple logo" height={height} src={apple} />
}

export function Google({size}) {
    const height = size === 'sm' ? '14px' : size === 'md' ? '16px' : size === 'lg' ? '40px' : '18px'
    return <img alt="Google logo" height={height} src={google} />
}
