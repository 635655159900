import { create_api } from "./base";

export default class AddressRepo {
    baseUrl = "/address";
  
    constructor() {
      this.api = create_api(process.env.REACT_APP_API_ROOT);
    }
  
    get = (id) => this.api.get(`${this.baseUrl}/${id}`).then(r => r.data)
    editLocation = (id, location, shipmentId) => this.api.post(`${this.baseUrl}/${id}/location`, location, { params: { shipment_id: shipmentId } }).then(r => r.data)
    editLocationOverride = (id, address) => this.api.post(`${this.baseUrl}/${id}/address`, address).then(r => r.data)
    relocate = (id) => this.api.post(`${this.baseUrl}/${id}/relocate`)
    enrich = (id) => this.api.post(`${this.baseUrl}/${id}/enrich`)
    formatAddress = (id, address) => this.api.post(`${this.baseUrl}/${id}/format-address`, address)
    deliveryHistory = (id) => this.api.get(`${this.baseUrl}/${id}/delivery-history`).then(r => r.status === 200 ? r.data : []).then(history => {
      history.sort((a,b) => b.id - a.id)
      return history
    })
    update = (address) => this.api.put(`${this.baseUrl}/${address.id}`, address).then(r => r.data)
    addAccessCode = (id, code, shipmentId, type) => this.api.put(`${this.baseUrl}/${id}/access-code`, {code, type, shipment_id: shipmentId}).then(r => r.data)
    
    addVerifyPhoto = (address_id, shipmentId, file, cb) => {
      const formData = new FormData();
      formData.append('image', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        params: { shipment_id: shipmentId }
      };

      this.api.post(`${this.baseUrl}/${address_id}/photo`, formData, config).then(r => {
        if (cb) {
          cb(r.data);
        }
      })
    }

    removeVerifyPhoto = (address_id, photo_id, shipmentId, cb) => {
      this.api.delete(`${this.baseUrl}/${address_id}/photo/${photo_id}`, null, { params: { shipment_id: shipmentId } }).then(r => {
        if (cb) {
          cb(r.data);
        }
      })
    }

    verifyPod = (address_id, pod_id) => this.api.put(`${this.baseUrl}/${address_id}/pod/${pod_id}`);
    unverifyPod = (address_id, pod_id) => this.api.delete(`${this.baseUrl}/${address_id}/pod/${pod_id}`);
    getEvents = (type) => this.api.get(`/events/${type}/all`).then(r => r.status === 200 ? r.data : null);
}
