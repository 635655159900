import { create_api } from "./base";

export default class GeocoderRepo {
  constructor() {
    this.api = create_api(process.env.REACT_APP_GEOCODER_API);
  }

  geocode = (address) => this.api.post(`/geocode?detail=true`, [address]).then(r => r.data).then(r => r[0])
  validate = (address) => this.api.post(`/address/validate`, address).then(r => r.data)
  verify = (id) => this.api.post(`/address/${id}/verify`)
}