import { create_api } from './base';

export default class IncidentRepo {
  baseUrl = '/incident';
  constructor() {
    this.api = create_api(process.env.REACT_APP_API_ROOT);
  }

  list = (page, type, statuses, tags, sort, date) =>
    this.api.get(
      `${this.baseUrl}?type=${type}&date=${date}&sort=${sort || 'priority'}&limit=20&offset=${((page || 1) - 1) * 20}&${statuses
        .map((x) => 'statuses=' + x)
        .join('&')}&${tags.map((t) => 'tags=' + t).join('&')}`
    );

  get = (id) => this.api.get(`${this.baseUrl}/${id}`);

  update = (id, incident) => this.api.put(`${this.baseUrl}/${id}`, incident);

  resolve = (incident, status, resolution, statusType) => {
    const params = { id: incident.id, status, resolution, status_type: statusType };

    if (status === 'RESOLVED') return this.api.post(`${this.baseUrl}/resolve`, params);

    return this.update(incident.id, { ...incident, ...params });
  };

  search = (filter, type, statuses, tags, sort, date) =>
    this.api.post(
      `${this.baseUrl}/search?type=${type}&date=${date}&sort=${sort || 'priority'}&${statuses
        .map((x) => 'statuses=' + x)
        .join('&')}&${tags.map((t) => 'tags=' + t).join('&')}`,
      filter
    );
}
