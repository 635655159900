import React, {useEffect, useState} from "react";
import { useRecoilValue } from "recoil";
import {useLoadShipmentCallback} from "../../states/shipment";
import {useParams} from "react-router-dom";
import {Grid} from "@material-ui/core";
import {selectedProfileState} from "../../states/profile";



function BeansAiContainer() {

    const params = useParams()
    const loadShipment = useLoadShipmentCallback()
    const [beansLoaded, setBeansLoaded] = useState(false)
    const [googleLoaded, setGoogleLoaded] = useState(false)
    const profile = useRecoilValue(selectedProfileState)


    const {
        address,
    } = profile || {}


    useEffect(() => {
        loadShipment(params.id)
    }, [ ])

    const useScript = (url, defer, async) => {
            useEffect(() => {
                const script = document.createElement('script');
                script.src = url;
                script.defer = defer
                script.async = async
                script.onload = function () {
                    setBeansLoaded(true)
                };

                document.body.appendChild(script);
                return () => {
                    document.body.removeChild(script);
                };

            }, [url]);
    };

    useEffect(() => {
        if(beansLoaded && profile && !googleLoaded) {

            window.initMap = initMap
            const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY || ""}&callback=initMap`
            const script = document.createElement('script');
            script.src = url
            script.defer = true
            script.async = true
            script.onload = function () {
                setGoogleLoaded(true)
            };

            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [beansLoaded, profile]);



    const initMap = () => {
        const addressString = getAddressString()
        const street2 = address?.street2 || ""
        const be1 = new window.BeansMap();
        be1.render(
            "body-inner-1",
            `${process.env.REACT_APP_BEANS_KEY}`,
            [
                {
                    address: addressString,
                    unit: street2
                },
            ],
            {},
            {
                initialMap: "STREET",
                showUnitShape: true,
                showDirections: true,
                showPath: true,
                showTiles: true,
                showLinks: true,
                hideBeansCard: false
            }
        );

        const be2 = new window.BeansMap();
        be2.render(
            "body-inner-2",
            `${process.env.REACT_APP_BEANS_KEY}`,
            [
                {
                    address: addressString,
                    unit: street2,
                    options: {
                        markers: {
                            unit: {
                                display: false
                            }
                        },
                        poi: [
                            {
                                name: "ALL",
                                display: false
                            }
                        ]
                    }
                }
            ],
            {
                userLocation: "LIVE"
            },
            {
                initialMap: "STREET",
                showUnitShape: true,
                showDirections: true,
                showPath: true,
                showTiles: true,
                hideBeansCard: false,
                unitShape: {
                    fillOpacity: 1.0,
                    fillColor: '#fff',
                    strokeWeight: 1.0,
                    strokeColor: '#000'
                }
            }
        );
    }

    const useScriptHead = url => {
        useEffect(() => {
            const script = document.createElement('link');
            script.href = url;
            script.rel = "stylesheet";
            document.head.appendChild(script);
            return () => {
                document.head.removeChild(script);
            };
        }, [url]);
    };


    const getAddressString = () => {
        const street = address?.street || ""
        const city = address?.city || ""
        const state = address?.state || ""
        const zip = address?.zip || ""
        const addressValues = [street,city,state,zip]
        return addressValues.join(" ")
    }


    useScriptHead("https://www.beans.ai/mapswidget/css/mapswidget-1.0.4.css")
    useScript("https://www.beans.ai/mapswidget/js/mapswidget-1.0.4.js", false, false, true)


    return <Grid container>
        <div id="body-inner-1" style={ {marginLeft: "12.5vw", marginTop: "10vh",  width:"50vw", height:"60vh", verticalAlign: "middle"}} />
        <div id="body-inner-2" style={ {marginLeft: "5vw", marginTop: "10vh",  width:"20vw", height:"80vw", display: "inline-block", verticalAlign: "middle"}}/>
    </Grid>


}

export function BeansAiIframe() {
    const params = useParams()
    const loadShipment = useLoadShipmentCallback()
    const profile = useRecoilValue(selectedProfileState)


    const {
        address,
    } = profile || {}


    useEffect(() => {
        loadShipment(params.id)
    }, [ ])

    const createDisplayOptions = () =>{
        let displayObject = {
            initialMap: "STREET",
            showUnitShape: true,
            showDirections: true,
            showPath: true,
            hideBeansCard: false
        }
        const jsonString = JSON.stringify(displayObject);
        return btoa(jsonString);

    }

    const createNavOptions = () =>{
        let navObject = {
        }
        const jsonString = JSON.stringify(navObject);
        return btoa(jsonString);

    }

    const createAddressOptions = () =>{
        const addressString = getAddressString()
        const street2 = address?.street2 || ""
        let addressObject = [
            {
                address: addressString,
                unit: street2
            },

        ]
        const jsonString = JSON.stringify(addressObject);
        return btoa(jsonString);
    }
    const createNavOptionsSmall = () =>{
        let navObject = {
            userLocation: "LIVE",
        }
        const jsonString = JSON.stringify(navObject);
        return btoa(jsonString);

    }
    const createAddressOptionsSmall = () =>{
        const addressString = getAddressString()
        const street2 = address?.street2 || ""
        let addressObject = [
            {
                address: addressString,
                unit: street2,
                options: {
                    markers: {
                        unit: {
                            display: false
                        }
                    },
                    poi: [
                        {
                            name: "ALL",
                            display: false
                        }
                    ]
                }
            },

        ]
        const jsonString = JSON.stringify(addressObject);
        return btoa(jsonString);
    }

    const createDisplayOptionsSmall = () =>{
        let displayObject = {
            initialMap: "STREET",
            showUnitShape: true,
            showDirections: true,
            showPath: true,
            showTiles: true,
            unitShape: {
                fillOpacity: 1.0,
                fillColor: '#fff',
                strokeWeight: 1.0,
                strokeColor: '#000'
            }
        }
        const jsonString = JSON.stringify(displayObject);
        return btoa(jsonString);

    }

    const getAddressString = () => {
        const street = address?.street || ""
        const city = address?.city || ""
        const state = address?.state || ""
        const zip = address?.zip || ""
        const addressValues = [street,city,state,zip]
        return addressValues.join(" ")
    }


    const createIframe = (addressOptions,navOptions,displayOptions) => {
        const request =  `https://www.beans.ai/mapswidget/google.html?addressOptions=${addressOptions}&navOptions=${navOptions}&displayOptions=${displayOptions}&apiKeySecret=${process.env.REACT_APP_BEANS_KEY||""}`
        return request
    }

    return <Grid container>
        <iframe allow="geolocation https://www.beans.ai; fullscreen" style={ {marginLeft: "12.5vw", marginTop: "10vh",  width:"50vw", height:"60vh", verticalAlign: "middle"}}
                src={createIframe(
                    createAddressOptions(),
                    createNavOptions(),
                    createDisplayOptions()
                    )} />
        <iframe allow="geolocation https://www.beans.ai; fullscreen" style={ {marginLeft: "5vw", marginTop: "10vh",  width:"20vw", height:"80vw", display: "inline-block", verticalAlign: "middle"}}
                src={createIframe(
                    createAddressOptionsSmall(),
                    createNavOptionsSmall(),
                    createDisplayOptionsSmall()
                )}
        />

    </Grid>
}

export default BeansAiContainer;

