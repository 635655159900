import React, { useState } from 'react';

import { TextField } from '@material-ui/core';

import { incidentDetailState, useResolveCallback, useGotoListPageCallback } from '../../states/incidents';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { FormControl } from '@material-ui/core';
import { H2 } from '../../components/Heading';
import { useRecoilValue } from 'recoil';

export default function IncidentResolutionContainer() {
  const selectedIncident = useRecoilValue(incidentDetailState);

  const [open, setOpen] = React.useState(false);
  const [resolveStatus, setResolveStatus] = useState('RESOLVED');
  const [resolution, setResolution] = useState();
  const resolve = useResolveCallback();
  const gotoList = useGotoListPageCallback();

  const handleClose = () => {
    setOpen(false);
    setResolution('');
  };

  const markIncident = (status) => {
    setOpen(true);
    setResolveStatus(status);
  };

  const markNoIssueWithAddress = (status) => {
    const statusType = 'NO_ISSUE_ADDRESS';
    resolve(status, undefined, statusType).then(() => {
      gotoList();
    });
  };

  const resolveIncident = () => {
    resolve(resolveStatus, resolution).then(() => {
      handleClose();
      gotoList();
    });
  };

  if (!selectedIncident) return <div></div>;

  return (
    <div>
      {selectedIncident.status !== 'CREATED' && <code>{selectedIncident.status}</code>}
      {selectedIncident.status !== 'RESOLVED' && (
        <>
          <Button variant="contained" style={{ marginLeft: 10 }} color="primary" onClick={() => markIncident('RESOLVED')}>
            Resolved - Valid
          </Button>
          <Button variant="contained" style={{ marginLeft: 10 }} onClick={() => markNoIssueWithAddress('RESOLVED')}>
            Resolved - No Issue with Address
          </Button>
        </>
      )}
      {selectedIncident.status !== 'UNSOLVABLE' && (
        <Button variant="contained" style={{ marginLeft: 10 }} color="secondary" onClick={() => markIncident('UNSOLVABLE')}>
          Mark as Unsolvable
        </Button>
      )}
      {selectedIncident.status !== 'DISPATCH_FOLLOWUP' && (
        <Button variant="contained" style={{ marginLeft: 10 }} onClick={() => markIncident('DISPATCH_FOLLOWUP')}>
          DISPATCH FOLLOW-UP
        </Button>
      )}
      <Dialog maxWidth="md" open={open} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitle>
          <H2>
            Mark Incident as <strong>{resolveStatus}</strong>
          </H2>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField multiline={true} variant={'outlined'} minRows={4} label="Resolution" onChange={(e) => setResolution(e.target.value)} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={resolveIncident} variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
