import { atom, selector } from 'recoil';

// import { selectedHistoricalDeliveriesState } from './shipment';
import moment from 'moment'
import {
    elevatorIcon,
    entranceIcon,
    parkingIcon,
    stairsIcon,
    svgMarkerBlue, svgMarkerDot,
    svgMarkerGreen, svgMarkerTarget,
    unitIcon
} from "../constants/mapIcons";




export const mapCenterState = atom({
    key: 'map-center-state',
    default: null,
})

export const trackingGpsState = atom({
    key: 'tracking-gps-state',
    default: []
})

export const mapZoomState = atom({
    key: 'map-zoom-state',
    default: 16,
})

export const mainMarkerState = atom({
    key: 'main-marker-state'
})
export const facilitiesMarkerState = atom({
    key: 'facilities-marker-state',
    default: []
})
export const unitMarkerState = atom({
    key: 'unit-marker-state',
    default: []
})
export const buildingMarkerState = atom({
    key: 'building-marker-state',
    default: []
})
export const entranceMarkerState = atom({
    key: 'entrance-marker-state',
    default: []
})
export const stairMarkerState = atom({
    key: 'stair-marker-state',
    default: []
})
export const elevatorMarkerState = atom({
    key: 'elevator-marker-state',
    default: []
})
export const parkingMarkerState = atom({
    key: 'parking-marker-state',
    default: []
})

export const dropPinsState = atom({
    key: 'drop-pins-state',
    default: []
})

// export const historyMarkers = selector({
//     key: 'delivery-markers-state',
//     get: ({get}) => {
//         const history = get(selectedHistoricalDeliveriesState)
//         return history ? history.filter(x => x.dropoff_address.lat).map(x => x.dropoff_address) : []
//     }
// })

export const refPinsState = atom({
    key: 'ref-pin-state',
    default: []
})

export const hoveredPinsState = atom({
    key: 'hovered-pin-state',
    default: []
})

export const mapMarkersState = selector({
    key: 'map-markers-state',
    get: ({get}) => {
        const mainMarker = get(mainMarkerState)
        // const history = get(historyMarkers)
        const drops = get(dropPinsState)
        const refPins = get(refPinsState)
        const hoveredPins = get(hoveredPinsState)
        const trackingGps = get(trackingGpsState)
        const unitMarker = get(unitMarkerState)
        const entranceMarker = get(entranceMarkerState)
        const stairMarker = get(stairMarkerState)
        const elevatorMarker = get(elevatorMarkerState)
        const parkingMarker = get(parkingMarkerState)
        const markers = trackingGps.map(l => Object.assign({}, {latLng: {lat: l.latitude, lng: l.longitude}, icon: svgMarkerDot, title: `${moment(l.timestamp).format('MMM DD, hh:mm:ssA')}`}))
            // .concat(history.map(x => Object.assign({}, {latLng: x})))
            .concat(mainMarker ? [{latLng: mainMarker, icon:unitIcon}] : [])
            .concat(drops.map(l => Object.assign({}, {latLng: l, icon: svgMarkerBlue})))
            .concat(refPins.map(l => Object.assign({}, {latLng: l, icon: svgMarkerGreen})))
            .concat(hoveredPins.map(l => Object.assign({}, {latLng: l, icon: svgMarkerTarget})))
            .concat(unitMarker.map(l => Object.assign({}, {latLng: l, icon: unitIcon,title:'Unit'})))
            .concat(entranceMarker.map(l => Object.assign({}, {latLng: l, icon: entranceIcon,title:'Entrance'})))
            .concat(stairMarker.map(l => Object.assign({}, {latLng: l, icon: stairsIcon,title:'Stairs'})))
            .concat(elevatorMarker.map(l => Object.assign({}, {latLng: l, icon: elevatorIcon,title:'Elevator'})))
            .concat(parkingMarker.map(l => Object.assign({}, {latLng: l, icon: parkingIcon, title:'Parking'})));

        return markers
    }
})
