import { Container, Paper } from "@material-ui/core";
import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useLoadShipmentCallback } from '../../states/shipment';
import ShipmentProfile from '../ShipmentProfile';


export default function ShipmentDetailContainer() {
    const params = useParams()
    const loadShipment = useLoadShipmentCallback()

    useEffect(() => {
      loadShipment(params.id)
    }, [ ])

    return (
        <Container maxWidth='xl' style={{ height: 'calc(100% - 65px)', paddingTop: 10 }}>
            <Paper elevation={2}>
                <Container  maxWidth='xl' style={{padding: 15}}>
                    <ShipmentProfile />
                </Container>
            </Paper>
        </Container>
    );
}