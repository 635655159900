import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

import { userState, useAuthentication } from '../states/auth';
import { useLoadAllClientsCallback } from '../states/client'

import { useRecoilState } from 'recoil';

import Header from '../components/Header';
import IncidentList from './IncidentList';
import IncidentDetailContainer from './IncidentDetail';
import ShipmentGeocodingContainer from './ShipmentGeocoding';
import CustomerProfileScreen from './CustomerProfileScreen';
import LandingPage from './LandingPage';
import ShipmentDetailContainer from './ShipmentDetail';
import {ToastBar, Toaster} from 'react-hot-toast';
import ValidateAddressContainer from "./ValidateAddress";
import BeansAiContainer from "./BeansAiContainer";



function RootContainer() {
    const [user] = useRecoilState(userState);
    const getUser = useAuthentication();
    const loadClient = useLoadAllClientsCallback()

    useEffect(() => {
        getUser();
        loadClient()
    }, []);

    if (!user) return <div>Loading...</div>;

    return (
        <React.Fragment>
            <Toaster>
                {(t) => (
                    <ToastBar
                        toast={t}
                        style={{}} // Overwrite styles
                        position="top-center" // Used to adapt the animation
                    />
                )}
            </Toaster>
            <Header user={user} />
            <Routes>
                <Route exact path={'/'} element={<LandingPage />} />
                <Route exact path={'/incident'} element={<LandingPage />} />
                <Route exact path={'/incident/:type'} element={<IncidentList base={`/geocode-failed`} type={`GEOCODE-FAILED`} />} />
                <Route exact path={'/incident/:type/page/:page'} element={<IncidentList base={`/geocode-failed`} type={`GEOCODE-FAILED`} />} />
                <Route exact path={'/incident/:type/:id'} element={<IncidentDetailContainer base={`/geocode-failed`} /> } />
                <Route exact path={'/shipment/:id'} element={<ShipmentGeocodingContainer /> } />
                <Route exact path={'/profile/:id'} element={<CustomerProfileScreen /> } />
                <Route exact path={'/shipments/:id'} element={<ShipmentDetailContainer /> } />
                <Route exact path={'/validate-address'} element={<ValidateAddressContainer /> } />
                {String(process.env.REACT_APP_USE_BEANS).toLowerCase() === 'true' &&
                <Route exact path={'/beans/:id'} element={<BeansAiContainer /> } /> }
            </Routes>
        </React.Fragment>
    )
}

export default RootContainer;
