import React from 'react';


import { useRecoilValue } from 'recoil';

import { incidentDetailState } from '../../states/incidents';
import { BlueBox } from '../../components/Box';
import { Grid } from '@material-ui/core';
import SuggestableGpsLocation from '../SuggestableGpsLocation'

const StrAsFloat = ({str, prec}) => {
    if (!str) return <span></span>
    const f = parseFloat(str).toFixed(prec || 5)
    return <span>{f}</span>
}

export default function WrongDropoffLocationContainer() {
    const incident = useRecoilValue(incidentDetailState)
    const { attributes, type } = incident || {}

    if (!incident || type !== 'WRONG-DROPOFF-LOCATION') return <></>

    const { distance, actual_lat, actual_lng } = attributes || {}

    return (
        <BlueBox style={{height: 40}}>
            <Grid container>
                <Grid item xs={12} container>
                    <Grid item xs={7}>
                        Actual location
                    </Grid>
                    <Grid item xs={5}>
                        Off distance
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={7}>
                        <SuggestableGpsLocation lat={actual_lat} lng={actual_lng} google={true} style={{color: '#273'}} />
                    </Grid>
                    <Grid item xs={5}>
                        <span style={{color: '#723'}}><StrAsFloat str={distance} prec={2} /> (m)</span>
                    </Grid>
                </Grid>
            </Grid>
        </BlueBox>
    );
}

