import React from 'react';
import moment from 'moment';

import { Typography, Grid } from "@material-ui/core";
import { H4 } from '../../components/Heading'
import { GrayBox, BlueBox } from '../Box';
import Dev from '../../containers/Dev';
import DirectionsIcon from '@material-ui/icons/Directions';
import Copiable from '../Copiable'
import GpsLocation from '../../containers/GpsLocation';

export default function ShipmentDetail({shipment}) {
    const { dropoff_note, customer, dropoff_access_code, tracking_code } = shipment || {}
    return <Grid container style={{paddingBottom: 10}}>
        <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div><code>{shipment.id}</code></div>
                <div><code>{shipment.status}</code>{ shipment.inbound_status && <code>|{shipment.inbound_status}</code>}</div>
            </div>
        </Grid>
        <Grid item container xs={12}>
            <Grid item xs={4}>
                <H4>Delivery Date:</H4>
                <Typography>{moment(shipment.dropoff_earliest_ts).format('MM/DD/YYYY')}</Typography>
            </Grid>
            <Grid item xs={6}>
                <H4>Tracking Code:</H4>
                <Typography style={{fontSize: '0.9em'}}>
                    <Copiable txt={ tracking_code } />
                    <a target="_blank" rel="noreferrer" href={`https://recipient.gojitsu.com/${tracking_code}`}>
                        <DirectionsIcon size="small" color='disabled' style={{marginBottom: -3}} />
                    </a>
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <H4>Location:</H4>
                { shipment.dropoff_address.lat && <GpsLocation hideNumber={true} lat={shipment.dropoff_address.lat} lng={shipment.dropoff_address.lng} google={true} /> }
            </Grid>
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
            <BlueBox>
                <Grid container>
                    <Grid item xs={4}>
                        <H4>Customer</H4>
                        <Typography>{customer.name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <H4>Phone</H4>
                        <Typography>{customer.phone_number}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <H4>Email</H4>
                        <Typography>{customer.email}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <H4>Access code:</H4>
                        <code>{ dropoff_access_code }</code>
                    </Grid>
                    <Grid item xs={7}>
                        <H4>Dropoff notes:</H4>
                        <Typography style={{fontSize: '0.9em', color: '#666'}}>{ dropoff_note }</Typography>
                    </Grid>
                </Grid>
            </BlueBox>
        </Grid>
        { shipment.dropoff_address && shipment.dropoff_address.lat &&
        <Dev><Grid item xs={12} style={{marginTop: 15}}>
            <GrayBox style={{ color: '#888'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <GpsLocation lat={shipment.dropoff_address.lat} lng={shipment.dropoff_address.lng} google={true} />
                    </Grid>
                    { shipment.dropoff_geocoder_id && <Grid item xs={12}>
                        <Typography>Current Geocoder ID</Typography>
                        <Typography>{shipment.dropoff_geocoder_id}</Typography>
                    </Grid> }
                </Grid>
            </GrayBox>
        </Grid></Dev>
        }
    </Grid>
}

