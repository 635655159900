import { atom, useRecoilCallback } from 'recoil';
import _ from 'lodash';

import { addressRepo, geocoderRepo } from '../repositories';
import {
    mapCenterState,
    mapZoomState,
    mainMarkerState,
    parkingMarkerState,
    facilitiesMarkerState,
    unitMarkerState,
    buildingMarkerState,
    entranceMarkerState,
    stairMarkerState,
    elevatorMarkerState
} from './map';
import { selectedShipmentState, useReloadShipmentCallback } from './shipment';
import toast from "react-hot-toast";
import {selectedProfileState, useSelectProfileCallback} from './profile';

export const selectedAddressIdState = atom({
    key: 'selected-address-id-state',
    default: null,
})

export const selectedAddressState = atom({
    key: 'selected-address-state',
    default: null,
})

export const useLoadAddressCallback = () => {
    return useRecoilCallback(({snapshot, set, reset}) => async (id) => {
        set(selectedAddressIdState, id)
        console.log('loading address', id)
        const address = id ? await addressRepo.get(id) : null
        console.log('Loaded', address)
        set(selectedAddressState, address)
        const { location, locations } = address || {}
        const latLng = location && location.latitude ? {lat: location.latitude, lng: location.longitude} : null
        set(mapCenterState, latLng)
        set(mainMarkerState, latLng)
        if (latLng) {
            set(mapZoomState, 17)
        }
        locations && locations.filter(l=>l.type === 'FACILITIES').map( l => {
            const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
            set(facilitiesMarkerState,[point])
        });
        locations && locations.filter(l=>l.type === 'UNIT').map( l => {
            const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
            set(unitMarkerState,[point])
        });
        locations && locations.filter(l=>l.type === 'BUILDING').map( l => {
            const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
            set(buildingMarkerState,[point])
        });
        locations && locations.filter(l=>l.type === 'ENTRANCE').map( l => {
            const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
            set(entranceMarkerState,[point])
        });
        locations && locations.filter(l=>l.type === 'STAIR').map( l => {
            const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
            set(stairMarkerState,[point])
        });
        locations && locations.filter(l=>l.type === 'ELEVATOR').map( l => {
            const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
            set(elevatorMarkerState,[point])
        });
        locations && locations.filter(l=>l.type === 'PARKING').map( l => {
            const point = l && l.latitude ? {lat: l.latitude, lng: l.longitude} : null
            set(parkingMarkerState,[point])
        });
    })
}

export const useReloadAddressCallback = () => {
    return useRecoilCallback(({snapshot, set, reset}) => async () => {
        const id = await snapshot.getPromise(selectedAddressIdState)
        const address = id ? await addressRepo.get(id) : null
        set(selectedAddressState, address)
    })
}

export const useEditAddressLocationCallback = () => {
    const reloadShipment = useReloadShipmentCallback()
    const selectProfile = useSelectProfileCallback()
    return useRecoilCallback(({ snapshot, set, reset }) => async (lat, lng, extra, override, shipmentId) => {
        const address = await snapshot.getPromise(selectedAddressState)
        if (!address) return
        const response = await addressRepo.editLocationOverride(address.id, {
            location: { latitude: lat, longitude: lng },
            uncharted: extra?.uncharted,
            pin_verified: extra?.pin_verified,
            override: override,
            shipment_id: shipmentId
        })
        if(response && response.code && response.code != 200){
            return response
        }else {
            await reloadShipment()
            await selectProfile()
        }
    })
}

export const useRelocateAddressCallback = () => {
    const reloadShipment = useReloadShipmentCallback()
    return useRecoilCallback(({snapshot, set, reset}) => async (lat, lng) => {
        const addressId = await snapshot.getPromise(selectedAddressIdState)
        if (!addressId) return
        const updated = await addressRepo.relocate(addressId)
        if (updated.status === 200)
            reloadShipment()
    })
}

export const useVerifyAddressCallBack = () => {
    const reloadShipment = useReloadShipmentCallback()
    return useRecoilCallback(({snapshot, set, reset}) => async (lat, lng) => {
        const addressId = await snapshot.getPromise(selectedAddressIdState)
        if (!addressId) return
        const updated = await geocoderRepo.verify(addressId)
        if (updated.status === 200)
            reloadShipment()
        if (updated.status === 404)
            alert('Cannot verify address!')
    })
}

export const useUpdateAddressCallback = () => {
    return useRecoilCallback(({snapshot, set}) => async (toUpdate) => {
        const address = await snapshot.getPromise(selectedAddressState)
        const updated = Object.assign({}, address, toUpdate)
        await addressRepo.update(updated)
        set(selectedAddressState, updated)
    })
}

export const useAddAccessCodeCallback = () => {
    return useRecoilCallback(({snapshot, set}) => async (code, shipmentId, type) => {
        const address = await snapshot.getPromise(selectedAddressState)
        if (!address) return
        const updated = await addressRepo.addAccessCode(address.id, code, shipmentId, type)
        set(selectedAddressState, updated)
    })
}

export const useEnrichAddressCallback = () => {
    const reloadShipment = useReloadShipmentCallback()

    return useRecoilCallback(({snapshot, set, reset}) => async (lat, lng) => {
        set(loadingAddressState, true)
        const addressId = await snapshot.getPromise(selectedAddressIdState)
        if (!addressId) return
        const updated = await addressRepo.enrich(addressId)
        if (updated.status === 200) {
            reloadShipment()
        }else{
            toast.error("Unable to enrich address, geocode failed")
        }
        set(loadingAddressState, false)
    })
}

export const verifiedPhotoState = atom({
  key: 'verified-photo-state',
  default: [],
})

export const loadingAddressState = atom({
    key: 'loading-address-state',
    default: false,
})

export const addressHistoriesState = atom({
    key: 'address-histories-state',
    default: [],
})

export const isLoadingState = atom({
    key: 'is-loading-state',
    default: false,
})

export const useAddressHistories = () => {
    return useRecoilCallback(({snapshot, set, reset}) => async () => {
        const shipment = await snapshot.getPromise(selectedShipmentState)
        const profile = await snapshot.getPromise(selectedProfileState)
        if (!shipment || !shipment.customer_profile_id) return;
        if (!profile || !profile.deliverable_address_id) return;
        
        set(isLoadingState, true);
        let data = [];
        let apis = [];
        await addressRepo.getEvents(`CP_${shipment.customer_profile_id}`).then(r => {
            data = [...data, ...r];
            apis.push(1);
        })

        await addressRepo.getEvents(`DA_${profile.deliverable_address_id}`).then(r => {
            data = [...data, ...r];
            apis.push(2);
        })

        if(apis.length == 2) {
            const sortData = _.sortBy(data, 'ts')?.filter(f => f && !_.isMatch(f.fact, f.state));
            set(addressHistoriesState, sortData);
            set(isLoadingState, false);
        }
    })
}
