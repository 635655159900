
import React from "react";
import {Container, Paper} from "@material-ui/core";
import AddressValidateForm from "../AddressValidateForm";

export default function ValidateAddressContainer() {
    return (
        <Container maxWidth='xl' style={{ height: 'calc(100% - 65px)', paddingTop: 10 }}>
            <Paper elevation={2}>
                <Container  maxWidth='xl' style={{padding: 15}}>
                    <AddressValidateForm />
                </Container>
            </Paper>
        </Container>
    );
}