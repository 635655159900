import React, { useEffect } from 'react';

import { CircularProgress, Container, Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { useLoadIncidentCallback, useGotoListPageCallback, useLoadFilterCallback } from '../../states/incidents';

import Button from "@material-ui/core/Button";
import IncidentResolutionContainer from './resolve';
import ShipmentProfile from '../ShipmentProfile';


export default function IncidentDetailContainer() {
    const params = useParams()
    const loadIncident = useLoadIncidentCallback()
    const gotoList = useGotoListPageCallback()
    const loadFilter = useLoadFilterCallback()

    useEffect(() => {
        loadIncident(params.id)
        loadFilter()
    }, [ ])

    const goBack = () => {
        gotoList()
    }

    return (
        <Container maxWidth='xl' style={{ height: 'calc(100% - 65px)', paddingTop: 10 }}>
            <Paper elevation={2}>
                <Container maxWidth='xl' style={{padding: 15, display: 'flex', justifyContent: 'space-between'}}>
                    <Button variant='outlined' onClick={ goBack }>Back</Button>
                    <React.Suspense fallback={<CircularProgress />} >
                        <IncidentResolutionContainer type={params.type} />
                    </React.Suspense>
                </Container>
                <Container  maxWidth='xl' style={{padding: 15}}>
                    <ShipmentProfile />
                </Container>
            </Paper>
        </Container>
    );
}

