import { create_api } from "./base";

export default class ShipmentRepo {
  baseUrl = "/shipments";
  constructor() {
    this.api = create_api(process.env.REACT_APP_API_ROOT);
  }

  pull = (ids) => this.api.post(`${this.baseUrl}/pull`, {ids}).then(r => r.data)
  get = (id) => this.api.get(`${this.baseUrl}/${id}`).then(r => r.data)
  getPODs = (id) => this.api.get(`${this.baseUrl}/${id}/pod`).then(r => r.status === 200 ? r.data : [])
  getOutboundInfo = (id) => this.api.get(`${this.baseUrl}/${id}/delivery-info`).then(r => r.status === 200 ? r.data : {})
  getEvents = (id) => this.api.get(`/events/SH_${id}/all?rel=true`).then(r => r.status === 200 ? r.data : null)
  search = (q, from, size) => this.api.post(`${this.baseUrl}/search`, {
    from,
    size,
    q,
    sorts: ['-dropoff_earliest_ts']
  }).then(r => r.data)
}