import {create_api} from "./base";

export default class DeliverableAddressRepo {
    baseUrl = "/";

    constructor() {
        this.api = create_api(process.env.REACT_APP_DELIVERABLE_ADDRESS_API);
    }

    get = (params) => this.api.get(`/address`, params)
}