import React, { useState } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { Dialog, Slide, Button, Box, Tooltip, CircularProgress } from "@material-ui/core";
import { CheckCircle as CheckIcon, RemoveCircle as RemoveIcon } from "@material-ui/icons";
import produce from "immer";

import { BlueBox } from '../../components/Box';
import SuggestableGpsLocation from '../SuggestableGpsLocation'
import { hoveredPinsState } from '../../states/map'
import { selectedAddressIdState, verifiedPhotoState } from "../../states/address";
import { selectedShipmentPicturesState } from "../../states/shipment";
import { addressRepo } from "../../repositories";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ShipmentPODContainer({pictures}) {
    const [ showPicture, setShowPicture ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [verifiedPhotos, setVerifiedPhotos] = useRecoilState(verifiedPhotoState)
    const setPin = useSetRecoilState(hoveredPinsState)
    const setPod = useSetRecoilState(selectedShipmentPicturesState)
    const addressId = useRecoilValue(selectedAddressIdState)
    const isVerified = showPicture.status === 'VERIFIED';

    const onMouseOver=(img) => {
        if (img.location)
            setPin([{lat: img.location.latitude, lng: img.location.longitude}])
    }
    const onMouseOut = () => setPin([])

    function closeImagePreview() {
      if (loading) {
        return;
      }
      setShowPicture({})
    }

    function onVerifyPOD(podId) {
      setLoading(true);
      addressRepo.verifyPod(addressId, podId).then(res => {
        if (res.ok) {
          const pod = {...showPicture, status: 'VERIFIED'};
          setPod(pod);
          setShowPicture(pod);
          setVerifiedPhotos(produce(verifiedPhotos, draft => {
            draft.push(res.data);
          }))
        } else {
          alert(`Error code ${res.status}`);
        }

        setLoading(false);
      })
    }

    function onUnverifyPOD(podId) {
      setLoading(true);
      addressRepo.unverifyPod(addressId, podId).then(res => {
        if (res.ok && res.data) {
          const pod = {...showPicture, status: 'CREATED'};
          setPod(pod);
          setShowPicture(pod);
          setVerifiedPhotos(produce(verifiedPhotos, draft => draft.filter(p => !res.data.includes(p.id))));
        } else {
          alert(`Error code ${res.status}`)
        }

        setLoading(false);
      })
    }

    if (!pictures || pictures.length < 1) return <></>

    return (
        <BlueBox style={{padding: 5}}>
            <div style={{display: 'flex'}}>
                { pictures.map(p => <div key={p.id} style={{textAlign: 'center', marginLeft: 5, marginRight: 5, position: "relative"}}>
                    <img alt='POD' src={p.url}
                         onMouseOver={() => onMouseOver(p)}
                         onMouseOut={onMouseOut}
                         style={{border: 'solid 1px #888', maxWidth: 60, maxHeight: 90, cursor: 'pointer', display: 'inline-block'}}
                         onClick={() => setShowPicture(p)}
                    />
                    {p.status === 'VERIFIED' && (
                      <Box style={{position: "absolute", top: 0, right: 0, color: '#00ff00', fontSize: 14}}>
                        <CheckIcon style={{verticalAlign: "bottom", display: 'block'}} fontSize="inherit" />
                      </Box>
                    )}
                    {p.location && <div>
                        <SuggestableGpsLocation size='sm' hideNumber={true} google={false} lat={p.location.latitude} lng={p.location.longitude} />
                    </div> }
                </div>) }
            </div>
            <Dialog
                open={!!showPicture.url}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeImagePreview}
            >
                <div style={{position: 'relative', overflow: 'auto'}}>
                  <img alt='Full POD' src={showPicture.url}
                       style={{border: 'solid 1px #888', maxWidth: 600, maxHeight: 'calc(100vh - 100px)', margin: 5}}
                  />
                  {isVerified && (
                    <Box style={{position: "absolute", top: 5, right: 5}}>
                      <Tooltip title="Un-verify">
                        <Box style={{display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer'}} px={0.5}>
                          {!loading ? <RemoveIcon color="primary" onClick={() => onUnverifyPOD(showPicture.id)} /> : <CircularProgress color="primary" size={24} />}
                        </Box>
                      </Tooltip>
                      <Box style={{border: '2px solid #00ff00', color: '#00ff00', display: 'inline-block', backgroundColor: 'rgba(0,0,0,0.3)'}} py={0.5} px={1}>
                        <CheckIcon style={{verticalAlign: "bottom"}} /> VERIFIED
                      </Box>
                    </Box>
                  )}
                  {!isVerified && (
                    <Button variant="contained" color="primary" style={{position: "absolute", top: 5, right: 5}}
                            disabled={loading}
                            onClick={() => onVerifyPOD(showPicture.id)}
                    >
                      {loading ? <CircularProgress color="primary" size={20} /> : 'Verify'}
                    </Button>
                  )}
                </div>
            </Dialog>
        </BlueBox>
    );
}

