import React from 'react';

import CustomerProfileDetail from './detail'
import { LinearProgress } from '@material-ui/core';

export default function CustomerProfileContainer({originalAddress}) {
    return (
        <React.Suspense fallback={<LinearProgress />}>
            <CustomerProfileDetail originalAddress={originalAddress} />
        </React.Suspense>
    );
}
