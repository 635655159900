import { create_api } from "./base";

export default class ClientRepo {
  baseUrl = "/clients";
  constructor() {
    this.api = create_api(process.env.REACT_APP_API_ROOT);
  }

  list = () => this.api.get(`${this.baseUrl}/active`).then(r => r.data)
  get = (id) => this.api.get(`${this.baseUrl}/${id}`).then(r => r.data)
}
