import React from 'react';

import withStyles from "@material-ui/core/styles/withStyles";

import { mapCenterState, mapZoomState, mapMarkersState, dropPinsState } from '../../states/map';
import { useRecoilState, useRecoilValue } from 'recoil';
import GeocodingMap from '../../components/GeocodingMap';
import CodeBloc from '../../components/CodeBloc'
import { Button } from '@material-ui/core';

const styles = () => ({
});

function AddressLocator() {
    const mapCenter = useRecoilValue(mapCenterState)
    const mapZoom = useRecoilValue(mapZoomState)
    const mapMarkers = useRecoilValue(mapMarkersState)
    const [ pins, setPins ] = useRecoilState(dropPinsState)
    const stringifiedPins = pins && pins.length > 0 ? pins.map(p => `${p.lat.toFixed(5)},${p.lng.toFixed(5)}`).join(' | ') : null
    return <div style={{width: '100%', height: '100%', position: 'relative'}}>
        <GeocodingMap initialZoom={mapZoom} google_key={`${process.env.REACT_APP_GOOGLE_KEY}`} markers={mapMarkers} center={mapCenter} />
        { stringifiedPins && <div style={{position: 'absolute', left: 15, bottom: 15, boxShadow: '0px 1px 1px #888', padding: 10, backgroundColor: '#fff', borderRadius: 8, zIndex: 10000}}>
            <CodeBloc style={{fontSize: '0.9em', marginRight: 10}}>{ stringifiedPins }</CodeBloc>
            <Button size='small' variant='outlined' onClick={() => setPins([])}>Clear</Button>
        </div> }
    </div>
}

export default withStyles(styles)(AddressLocator);
