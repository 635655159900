import {Grid, Container, CircularProgress} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import GeocodingMap from '../../components/GeocodingMap';
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {deliverableAddressRepo} from '../../repositories';
import {H5, H4} from '../../components/Heading'
import { ColorBox } from '../../components/Box';
import { Apple, Google } from '../../components/Icons';
import { Search } from '@material-ui/icons';
import Copiable from '../../components/Copiable';
import {useRecoilValue} from "recoil";
import {incidentDetailState} from "../../states/incidents";

export default function AddressEditorContainer({orig, onClose, onSave, onOverride, classes}) {
    const [address, setAddress] = useState(orig)
    const [latLng, setLatLng] = useState()
    const [goodToGo, setGoodToGo] = useState()
    const [geocoded, setGeocoded] = useState()
    const [zoom, setZoom] = useState()
    const [validation, setValidation] = useState(undefined)
    const [flagged, setFlagged] = useState([])
    const [fullAddress, setFullAddress] = useState()
    const [loading, setLoading] = useState(false)
    const selectedIncident = useRecoilValue(incidentDetailState);

    useEffect(() => {
        setAddress(orig)
        setLatLng(orig && orig.lat ? {lat: orig.lat, lng: orig.lng} : null)
        setValidation(null)
    }, [orig])

    const isDiff = (str, orig) =>{
        if(str === null || str === undefined ) { //we will let empty strings through
            return false;
        }
        return (str && !orig) || (orig && !str) || (str && orig && str.trim().toUpperCase() !== orig.trim().toUpperCase());
    }

    const isDiffZip = (addressComponents, orig) => {
        if(!addressComponents?.componentNotes?.zip && !addressComponents?.componentNotes?.zip4){
            return false;
        }

        if(!!addressComponents?.componentNotes?.zip){
            return true;
        }

        if(orig.includes('-') && !!addressComponents?.zip4){
            return true;
        }
        return false;
    }

    const getDiffZip = (addressComponents, orig) => {
        if(!addressComponents?.componentNotes?.zip && !addressComponents?.componentNotes?.zip4){
            return null;
        }

        let zip4 = ''
        if(orig.includes('-') && !!addressComponents?.zip4){
            zip4 = `-${addressComponents?.zip4}`
        }
        return`${addressComponents.zip}${zip4}`
    }

    const validate = async () => {
        setLoading(true)
        let params = {...address, street1: address?.street, force_true: 'true'}

        const response = await deliverableAddressRepo.get(params)
        if (response.status == 200) {
            const geocode = response.data.geocode
            setLatLng({lat: geocode.latitude, lng: geocode.longitude});
            setGeocoded({location: geocode})

            let isCorrected = false;
            let updatedAddress = {...address};
            let corrections = []
            if (response?.data?.addressComponents?.isCorrected == true) {
                const addressComponents = response.data?.addressComponents;
                const full = address ? `${addressComponents?.streetLine}, ${addressComponents?.city}, ${addressComponents?.state} ${addressComponents?.zip}`.replaceAll('#', ' ') : ''
                setFullAddress(full)

                if (isDiff(addressComponents?.streetLine,updatedAddress.street)) {
                    updatedAddress.street = addressComponents?.streetLine
                    corrections.push("corrected street to " + addressComponents?.streetLine)
                }

                if (isDiff(addressComponents?.secondaryAddressLine,updatedAddress.street2)) {
                    updatedAddress.street2 = addressComponents?.secondaryAddressLine
                    corrections.push("corrected street2 to " + addressComponents?.secondaryAddressLine)
                }
                if (!!addressComponents?.componentNotes?.city) {
                    updatedAddress.city = addressComponents?.city
                    corrections.push("corrected city to " + addressComponents?.city)
                }

                if (!!addressComponents?.componentNotes?.state) {
                    updatedAddress.state = addressComponents?.state
                    corrections.push("corrected state to " + addressComponents?.state)
                }
                if (isDiffZip(addressComponents, updatedAddress.zipcode)) {
                    const correctedZip = getDiffZip(addressComponents, updatedAddress.zipcode)
                    updatedAddress.zipcode = correctedZip;
                    corrections.push("corrected zipcode to " + correctedZip)
                }

                setAddress(updatedAddress)
                isCorrected = true;

            }
            const dasResponse = {
                status: 'success',
                isCorrected: isCorrected,
                corrections: corrections
            }
            setValidation(dasResponse)
            setAddress(updatedAddress)
            setLoading(false)
            if(response.data?.flaggedIssues && response.data?.flaggedIssues.length > 0){
                setFlagged(response.data.flaggedIssues)
            }else{
                setFlagged([])
            }

        } else {
            setLatLng(null)
            const dasResponse = {
                status: 'failed',
                message: response.data?.message
            }
            setValidation(dasResponse)
            setFlagged([])
            setLoading(false)
        }
    }

    useEffect(() => {
        setGoodToGo(geocoded && geocoded.location && geocoded.location.latitude)
    }, [geocoded])

    const updateAddress = (datatype) => ((event) => {
        let data = {}
        data[datatype] = event.target.value
        setAddress(a => Object.assign({}, a, data))
        setGeocoded(null)
        setValidation(null)
    })

    useEffect(() => {
        const full = address ? `${address.street}, ${address.city}, ${address.state} ${address.zipcode}`.replaceAll('#', ' ') : ''
        setFullAddress(full)
    }, [address])


    const addressEditor = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <H4>Street</H4>
                    <OutlinedInput onChange={updateAddress('street')} fullWidth style={{height: 40}}
                                   value={address ? address.street : ''}/>
                </Grid>
                <Grid item xs={12}>
                    <H4>Street2</H4>
                    <OutlinedInput onChange={updateAddress('street2')} fullWidth style={{height: 40}}
                                   value={address ? address.street2 : ''}/>
                </Grid>
                <Grid item xs={12}>
                    <H4>City</H4>
                    <OutlinedInput onChange={ updateAddress('city') } fullWidth style={{height: 40}} value={address ? address.city : ''} />
                </Grid>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={5}><H4>State</H4> </Grid>
                    <Grid item xs={7}><H4>Zipcode</H4> </Grid>
                    <Grid item xs={5}>
                        <OutlinedInput onChange={ updateAddress('state') } fullWidth style={{height: 40}} value={address ? address.state : ''} />
                    </Grid>
                    <Grid item xs={7}>
                        <OutlinedInput onChange={ updateAddress('zipcode') } fullWidth style={{height: 40}} value={address ? address.zipcode : ''} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ColorBox style={{marginTop: 8, fontSize: '13px', color: '#555'}}>
                        <Copiable txt={ fullAddress } />
                        <span style={{display: 'inline-block'}}>
                        <a style={{marginLeft: 10}} target='_blank' rel="noreferrer" href={`https://maps.google.com/?q=${fullAddress}`}><Google size='sm' /></a>
                        <a style={{marginLeft: 10}} target='_blank' rel="noreferrer" href={`https://maps.apple.com/?address=${fullAddress}`}><Apple size='md' /></a>
                        <a style={{marginLeft: 10, color: '#444'}} target='_blank' rel="noreferrer" href={`https://google.com/search?q=${fullAddress}`}><Search style={{marginBottom: -4}} fontSize='small' /></a>
                        </span>
                    </ColorBox>
                </Grid>
                <Grid item container xs={12}>
                    <Container style={{display: 'flex', justifyContent: 'space-between', height: 40, marginTop: 10, padding: 0}}>
                        <Button style={{fontSize: 11}} size={'small'} onClick={validate}>Validate and Geocode</Button>
                        <Button style={{fontSize: 11}} disabled={!goodToGo} size={'small'}
                                onClick={() => onSave && onSave(address)}>{ !!selectedIncident ? 'Save and Resolve' : 'Save'}</Button>
                        <Button style={{fontSize: 11}} size={'small'} onClick={ () => onClose && onClose() }>Close</Button>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    {validation && <ColorBox color={validation.status || ''}>
                        <H4>Geocode Result</H4>
                        <Grid container>
                            <Grid item xs={3}><H5>status:</H5></Grid>
                            <Grid item xs={9}>{validation.status}</Grid>
                            {validation.status === 'success' ?
                                <Grid container>
                                    <Grid item xs={3}><H5>source:</H5></Grid>
                                    <Grid item xs={9}>{geocoded?.location.source}</Grid>
                                    {geocoded.location && <Grid item
                                                                xs={12}>result: {geocoded.location.latitude}, {geocoded.location.longitude}</Grid>}
                                    {validation.isCorrected &&
                                        <Grid container style={{marginTop: 8, fontSize: '13px', color: 'red'}}>
                                            <Grid item xs={3}><H5>Corrections:</H5></Grid>
                                            {
                                                validation?.corrections.map((correction, i) => {
                                                    return <Grid item xs={12} key={i}>{correction}</Grid>
                                                })
                                            }
                                        </Grid>
                                    }
                                </Grid> :
                                <Grid container>
                                    <Grid item xs={3}><H5>error:</H5></Grid>
                                    <Grid item xs={12}>{validation.message}</Grid>
                                </Grid>
                            }
                        </Grid></ColorBox>}
                </Grid>
                <Grid item xs={12}>
                    { flagged && flagged.length > 0 &&
                        <ColorBox color={'failed'}>
                            <H4>Address was retrieved from cache but it appears to to have some validation issues:</H4>
                            <Grid container>
                                {
                                    flagged.map((flag, i) => {
                                        return <Grid item xs={12} key={i}>{flag}</Grid>
                                    })
                                }
                            </Grid>
                        </ColorBox>}
                </Grid>
                <Grid item xs={12}>
                    { validation && validation.status && validation.status !== 'success' && <ColorBox color={validation.status || ''}>
                        <Grid container>
                            <Grid item xs={8}>{ validation.status !== 'success' &&  <Button style={{background:'red'}} onClick={ () => onOverride && onOverride(address) }>Click to OVERRIDE Address</Button>}</Grid>
                        </Grid></ColorBox>}
                </Grid>
            </Grid>

        )
    }

    return <Grid container spacing={1}>
        <Grid item xs={5} style={{minHeight: 540}}>
            {loading ?
                <CircularProgress color="primary" size={20}/> :
                addressEditor()
            }
        </Grid>
        <Grid item xs={7}>
            <GeocodingMap center={latLng} initialZoom={zoom} google_key={`${process.env.REACT_APP_GOOGLE_KEY}`}
                          markers={latLng ? [{latLng}] : []}/>
        </Grid>
    </Grid>
}
