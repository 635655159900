import AuthRepo from './auth'
import CustomerProfileRepo from './customer_profile'
import IncidentRepo from './incident';
import ShipmentRepo from './shipment';
import AddressRepo from './address';
import GeocoderRepo from './geocoder';
import ClientRepo from './client';
import AssignmentRepo from './assignment';
import NoteRepo from './note';
import RegionRepo from './region';
import DeliverableAddressRepo from "./deliverable_address";

export const authRepo = new AuthRepo()
export const customerProfileRepo = new CustomerProfileRepo();
export const incidentRepo = new IncidentRepo();
export const shipmentRepo = new ShipmentRepo();
export const addressRepo = new AddressRepo();
export const geocoderRepo = new GeocoderRepo();
export const clientRepo = new ClientRepo();
export const assignmentRepo = new AssignmentRepo();
export const noteRepo = new NoteRepo();
export const regionRepo = new RegionRepo();
export const deliverableAddressRepo = new DeliverableAddressRepo();