import React from 'react';

import withStyles from "@material-ui/core/styles/withStyles";

import { customerDeliverySelector } from '../../states/shipment';
import { useRecoilValue } from 'recoil';

import DeliveryHistoryItem from '../HistoryItem';

const styles = () => ({
    error: { border: "solid 1px #f4485e", borderLeft: 'solid 8px #f4485e', padding: 10, marginBottom: 6, borderRadius: 5 },
    success: { border: "solid 2px #75c31e", borderLeft: 'solid 8px #75c31e', padding: 10, marginBottom: 6, borderRadius: 5 },
    failed: { border: "solid 2px #4a4a4a", borderLeft: 'solid 8px #4a4a4a', padding: 10, marginBottom: 6, borderRadius: 5 },
    item: {
        fontSize: '15px',
        padding: '2px',
        color: '#5a5a5a',
    }
});

function DeliveryHistory() {
    const shipments = useRecoilValue(customerDeliverySelector)
    return <div style={{height: 'calc(100% - 4px)', width: '95%', position: 'relative', padding: 0}}>
        <div style={{position: 'absolute', padding: '10px 0', width: '100%'}}>        
        { shipments && shipments.map(shipment => <DeliveryHistoryItem showCustomer={true} key={shipment.id} shipment={shipment} />)}
        </div>
    </div>
}

export default withStyles(styles)(DeliveryHistory);
