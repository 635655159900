import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { noteRepo } from "../../repositories";
import moment from "moment";
import CodeBloc from "../../components/CodeBloc";

export default function NoteContainer({uid}) {
    const [ notes, setNotes ] = useState([])
    const [ openAddingNote, setOpenAddingNote ] = useState(false)
    const [ editingNote, setEditingNote ] = useState('')

    useEffect(() => {
        setNotes([])
        noteRepo.get(uid).then(r => {
            setNotes(r)
        })
    }, [uid])

    const startAddingNote = () => {
        setOpenAddingNote(true)
        setEditingNote('')
    }

    const addNote = () => {
        setOpenAddingNote(false)
        if (!editingNote) return;
        noteRepo.add(uid, editingNote).then(r => { noteRepo.get(uid).then(updated => { setNotes(updated) }); setEditingNote('') } )
    }

    return <div style={{maxHeight: 280, overflow: 'auto'}}>
        { !openAddingNote && <div><Button size='small' onClick={startAddingNote}>Add Note</Button></div> }
        { openAddingNote && <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button size='small' onClick={() => setOpenAddingNote(false)}>Cancel</Button>
                <Button size='small' onClick={addNote}>Save</Button>
            </div>
            <TextField multiline maxRows={3} variant='outlined' fullWidth value={editingNote} onChange={(e) => setEditingNote(e.target.value)} />
        </div>}
        { notes && notes.map(note => <div key={note.id} style={{padding: 5, border: 'solid 1px #eee', borderRadius: 5, marginTop: 3}}>
            <div style={{fontSize: '0.9em', display: 'inline-block', color: '#777'}}>
                <CodeBloc style={{marginRight: 6, fontSize: '0.9em'}}>{ moment(note.ts).format('MM/DD hh:mm A ')}  { note.subject.attributes.name }</CodeBloc>
            </div>
            <span style={{fontWeight: 600}}>{note.content}</span>
        </div>)}
    </div>
}