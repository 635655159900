import { atom, selector, useRecoilCallback, selectorFamily, waitForNone, waitForAll } from 'recoil';
import produce from 'immer';
import moment from 'moment';

import { shipmentRepo, addressRepo, assignmentRepo } from '../repositories';
import { trackingGpsState } from './map';

import { useSelectProfileCallback, selectedProfileState } from './profile'
import { clientsSelector }  from './client'

export const selectMultiple = atom({
    key: 'history-select-mode-state',
    default: false
})

export const selectedShipmentIdState = atom({
    key: 'selected-shipment-id-state',
    default: null,
})

export const selectedShipmentState = atom({
    key: 'selected-shipment-state',
    default: null,
})

export const selectedShipmentInfoState = atom({
    key: 'selected-shipment-info-state',
    default: null,
})

export const selectedHistoricalDeliveryIdsState = atom({
    key: 'selected-history-delivery-ids-state',
    default: []
})

export const selectedShipmentPodsState = selectorFamily({
    key: 'selected-shipment-pods-selector',
    get: (id) => async ({get}) => {
        return id ? await shipmentRepo.getPODs(id) : []
    }
})


export const selectedShipmentEventsState = selectorFamily({
    key: 'selected-shipment-events-selector',
    get: (id) => async ({get}) => {
        return id ? await shipmentRepo.getEvents(id) : []
    }
})

export const shipmentOutboundInfo = selectorFamily({
    key: 'shipment-outbound-info-selector',
    get: (id) => async() => {
        return id ? await shipmentRepo.getOutboundInfo(id) : {}
    }
})

export const selectedShipmentTrackingGPSState = selector({
    key: 'selected-shipment-tracking-gps-selector',
    get: async ({get}) => {
        const shipment = get(selectedShipmentState)
        if (!shipment || !shipment.assignment_id) return []
        const locations = await assignmentRepo.getTrackingGps(shipment.assignment_id)
        return locations
    }
})

export const selectedShipmentPicturesState = selector({
    key: 'selected-shipment-pictures-selector',
    get: async ({get}) => {
        const info = await get(selectedShipmentInfoState)
        const { pods } = info || {}
        return pods ? pods.filter(p => p.type === 'picture') : []
    },
    set: ({get, set}, data) => {
        const info = get(selectedShipmentInfoState)

        set(selectedShipmentInfoState, produce(info, draft => {
            draft.pods = draft.pods.map(p => {
                if (p.id === data.id) {
                    return data;
                }
                return p;
            });
        }))
    }
})

export const useToggleHistorySelectionCallback = () => {
    return useRecoilCallback(({snapshot, set, reset}) => async (shipment) => {
        const mulitple = await snapshot.getPromise(selectMultiple)
        const current = await snapshot.getPromise(selectedHistoricalDeliveryIdsState)
        const existing = current ? current.indexOf(shipment.id) : -1
        const updated = mulitple ? (existing < 0 ? current.concat([shipment.id]) : current.slice(0, existing).concat(current.slice(existing + 1)))
            : (existing < 0 ? [shipment.id] : [])
        set(selectedHistoricalDeliveryIdsState, updated)
    })
}

export const isSelectedHistoricalDeliveryState = selectorFamily({
    key: 'is-selected-historical-delivery-state',
    get: (id) => ({get}) => {
        const current = get(selectedHistoricalDeliveryIdsState)
        return current && current.indexOf(id) > -1
    }
})

export const selectedHistoricalDeliveriesState = selector({
    key: 'selected-history-deliveries-state',
    get: ({get}) => {
        const ids = get(selectedHistoricalDeliveryIdsState)
        let history = get(waitForNone([customerDeliverySelector, shipmentSearchResultItemsSelector]))
            .filter(({state}) => state === 'hasValue')
            .map(({contents}) => contents);
        history.sort((a,b) => a.ts - b.ts)
        return history.flat().filter(x => ids.indexOf(x.id) >= 0);
    }
})

export const historicalDeliveriesState = selectorFamily({
    key: 'selected-history-deliveries-state',
    get: (id) => async ({get}) => {
        // const outboundInfo = await shipmentRepo.getOutboundInfo(id)
        const [outboundInfo, pods, events] = await get(waitForAll([
            shipmentOutboundInfo(id),
            selectedShipmentPodsState(id),
            selectedShipmentEventsState(id)
        ]))
        // map events to pods
        const locatedPods = pods.map(p => {
            const ee = events.filter(e => e.state && e.state.uid === p.uid).filter(e => e.location && e.location.geolocation)
                .map(e => e.location.geolocation)
            const location = ee.length > 0 ? ee[0] : null
            return location ? {...p, location} : p
        })
        return {...outboundInfo, events, pods: locatedPods}
    }
})


export const useSelectShipmentCallback = () => {
    const selectProfile = useSelectProfileCallback()
    return useRecoilCallback(({snapshot, set, reset}) => async (id) => {
        set(selectedShipmentIdState, id)
        const outboundInfo = id ? await shipmentRepo.getOutboundInfo(id) : null
        const { shipment } = outboundInfo || {}
        set(selectedShipmentState, shipment)
        set(selectedShipmentInfoState, outboundInfo)

        if (shipment) {
            snapshot.getLoadable(clientsSelector(shipment.client_id))
        }
        selectProfile(shipment ? shipment.customer_profile_id : null)

        if (!id) set(selectedHistoricalDeliveryIdsState, [])

        const events = id ? await snapshot.getPromise(selectedShipmentEventsState(id)) : [] // await shipmentRepo.getEvents(id)
        if (id) {
            const pods = await snapshot.getPromise(selectedShipmentPodsState(id)) // await shipmentRepo.getPODs(id)
            const locatedPods = pods.map(p => {
                const ee = events.filter(e => e.state && e.state.uid === p.uid).filter(e => e.location && e.location.geolocation)
                    .map(e => e.location.geolocation)
                const location = ee.length > 0 ? ee[0] : null
                return location ? {...p, location} : p
            })
            set(selectedShipmentInfoState, (old) => Object.assign({}, old, {events, pods: locatedPods}))
        }

        
        if (shipment && shipment.assignment_id) {
            const locations = await assignmentRepo.getTrackingGps(shipment.assignment_id)
            if (locations.length < 10) set(trackingGpsState, locations)
            else {
                const started = events.filter(e => e.type === 'OUTBOUND' && e.category === 'STOP' && e.action === 'update_dropoff' && e.state && e.state.status === 'EN_ROUTE')
                const startedTs = started.length > 0 ? started[0].ts : 0
                const finished = events.filter(e => e.type === 'OUTBOUND' && e.category === 'STOP' && e.action === 'update_dropoff' && e.state && e.state.status === 'SUCCEEDED')
                const finishedTs = finished.length > 0 ? finished[0].ts : 0
                let timedLocations = locations.filter(l => moment(l.timestamp).unix() * 1000 < finishedTs + 60000 && moment(l.timestamp).unix() * 1000 > Math.max(finishedTs - 600000, startedTs))
                if (timedLocations.length > 100) {
                    timedLocations = timedLocations.slice(timedLocations.length - 100)
                }
                const limited = locations.filter(l => l.latitude < shipment.dropoff_address.lat + 0.005)
                    .filter(l => l.latitude > shipment.dropoff_address.lat - 0.005)
                    .filter(l => l.longitude < shipment.dropoff_address.lng + 0.005)
                    .filter(l => l.longitude > shipment.dropoff_address.lng - 0.005)
                set(trackingGpsState, finishedTs > 0 ? timedLocations : limited)
            }
        } else {
            set(trackingGpsState, [])
        }
    })
}

export const useReloadShipmentCallback = () => {
    const selectProfile = useSelectProfileCallback()
    return useRecoilCallback(({snapshot, set, reset}) => async () => {
        const id = await snapshot.getPromise(selectedShipmentIdState)
        const outboundInfo = id ? await shipmentRepo.getOutboundInfo(id) : null
        const { shipment } = outboundInfo || {}
        set(selectedShipmentState, shipment)
        set(selectedShipmentInfoState, outboundInfo)
        selectProfile(shipment ? shipment.customer_profile_id : null)
        if (id) {
            const events = await snapshot.getPromise(selectedShipmentEventsState(id)) // await shipmentRepo.getEvents(id)
            const pods = await snapshot.getPromise(selectedShipmentPodsState(id)) // await shipmentRepo.getPODs(id)
            const locatedPods = pods.map(p => {
                const ee = events.filter(e => e.state && e.state.uid === p.uid).filter(e => e.location && e.location.geolocation)
                    .map(e => e.location.geolocation)
                const location = ee.length > 0 ? ee[0] : null
                return location ? {...p, location} : p
            })
            set(selectedShipmentInfoState, (old) => Object.assign({}, old, {events, pods: locatedPods}))
        }

        
        if (shipment && shipment.assignment_id) {
            const locations = await assignmentRepo.getTrackingGps(shipment.assignment_id)
            if (locations.length < 10) set(trackingGpsState, locations)
            else {
                const limited = locations.filter(l => l.latitude < shipment.dropoff_address.lat + 0.005)
                    .filter(l => l.latitude > shipment.dropoff_address.lat - 0.005)
                    .filter(l => l.longitude < shipment.dropoff_address.lng + 0.005)
                    .filter(l => l.longitude > shipment.dropoff_address.lng - 0.005)
                set(trackingGpsState, limited)
            }
        } else {
            set(trackingGpsState, [])
        }
    })
}

export const searchQueryState = atom({
    key: 'shipment-search-query-atom',
    default: {
        q: 'NONE',
        from : 0,
        size: 15
    },
})

export const shipmentSearchSelector = selector({
    'key': 'shipment-search-selector',
    get: async ({get}) => {
        const query = get(searchQueryState)
        const {q, from, size} = query
        const data = await shipmentRepo.search(q, from, size)
        const shipments = data && data.results ? data.results.map(r => r.shipment) : []
        return {
            offset: 0,
            limit: 50,
            total: data.total,
            items: shipments
        }
    }
})

export const shipmentSearchResultItemsSelector = selector({
    'key': 'shipment-search-result-items-selector',
    get: ({get}) => {
        const result = get(shipmentSearchSelector)
        return result ? result.items || [] : []
    }
})

export const customerDeliverySelector = selector({
    key: 'selected-customer-delivery-selector',
    get: async({get}) => {
        const profile = get(selectedProfileState)
        if (!profile || !profile.deliverable_address_id) return []
        let history = addressRepo.deliveryHistory(profile.deliverable_address_id)
        return history
    }
})

export const useLoadShipmentCallback = () => {
  const selectShipment = useSelectShipmentCallback()
  return useRecoilCallback(({snapshot, set, reset}) => async (id) => {
    selectShipment(id)
  })
}