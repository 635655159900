import React, { useEffect } from 'react';

import {CircularProgress, DialogActions, DialogTitle, Divider, Grid, TextField} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import AddressEditorContainer from '../AddressEditor'

import {
    profileLoadingState,
    selectedProfileState,
    useCorrectAddressCallback,
    useEditInstructionsCallback
} from '../../states/profile';
import {useResolveCallback, incidentDetailState, useGotoListPageCallback} from '../../states/incidents';
import {useRecoilState, useRecoilValue} from 'recoil';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import AddressInfoBox from '../../components/AddressInfoBox'
import { BlueBox } from '../../components/Box';
import Dev from '../Dev';
import { H4 } from '../../components/Heading';
import {loadingAddressState, useEnrichAddressCallback} from "../../states/address";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { selectedShipmentIdState } from "../../states/shipment";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const styles = () => ({
});

function CustomerProfileDetail({ originalAddress }) {
    const profile = useRecoilValue(selectedProfileState)
    const {
        corrected_address,
        id,
        address,
        instructions,
        total_customer_negative_feedbacks,
        total_customer_positive_feedbacks
    } = profile || {}
    const correctProfileAddress = useCorrectAddressCallback()
    const editInstructions = useEditInstructionsCallback()
    const enrichAddress = useEnrichAddressCallback()
    const loadingAddress = useRecoilValue(loadingAddressState)
    const loadingProfile = useRecoilValue(profileLoadingState)
    const [open, setOpen] = React.useState(false);
    const [openInstructionsDialog, setOpenInstructionsDialog] = React.useState(false)
    const [editingInstructions, setEditingInstructions] = React.useState()
    const shipmentId = useRecoilValue(selectedShipmentIdState)
    const [openVerifyLatLngOnOverrideDialog, setOpenVerifyLatLngOnOverrideDialog] = React.useState(false)

    const resolve = useResolveCallback();
    const selectedIncident = useRecoilValue(incidentDetailState);
    const gotoList = useGotoListPageCallback()

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleEnrich = async () => {
        enrichAddress();
    };

    const correctAddress = (addr) => {
        correctProfileAddress(addr, shipmentId).then(() => {
            if (selectedIncident && selectedIncident.type !== 'WRONG-DROPOFF-LOCATION') {
              resolve('RESOLVED').then(() => {
                  const shipment_id = selectedIncident ? parseInt(selectedIncident.object.split('_')[1]) : null
                  toast.success(`Successfully Resolved Incident for shipment ${shipment_id}`)
                  gotoList();
              });
            }else{
                toast.success(`Successfully updated shipment ${shipmentId} and customer profile`)
            }
        }).finally(() => {
            setOpen(false)
        })
    }

    const onAddressOverride = (addr) => {
        correctProfileAddress(addr, shipmentId, true).then(() => {
            toast.success(`Successfully Updated Address Only for shipment ${shipmentId}`)
        }).finally(() => {
            setOpen(false)
            setOpenVerifyLatLngOnOverrideDialog(true)
        })
    }

    const saveInstrucions = () => {
        editInstructions(editingInstructions, shipmentId)
        setOpenInstructionsDialog(false)
    }

    useEffect(() => {
        setEditingInstructions(instructions || '')
    }, [instructions])

    return <Grid container>
        <div style={{position: 'relative'}}>
            <BlueBox>
                <Dev><Typography style={{fontSize: '0.8em'}}>{id}</Typography></Dev>
                <AddressInfoBox origin={originalAddress || address || {}} address={corrected_address || address || originalAddress || {} } />
                <Divider style={{marginTop: 10}} />

                <H4>Customer feedbacks</H4>
                <div>
                    <span style={{ display:'inline-flex', alignItems: 'center', color: '#4abc4e' }} className="legend-label">
                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"></path></svg>

                        <span style={{ margin: 5, color: 'blue', fontSize: '1.2em'}}>{total_customer_positive_feedbacks}</span>
                    </span>

                    <span style={{ display:'inline-flex', alignItems: 'center', color: '#d0021b', marginLeft: 30 }} className="legend-label">
                        <svg className="MuiSvgIcon-root jss715" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z"></path></svg>

                        <span style={{ margin: 5, color: '#d0021b', fontSize: '1.2em'}}>{total_customer_negative_feedbacks}</span>
                    </span>
                </div>

                <Divider style={{marginTop: 10}} />
                <div style={{position: 'relative'}}>
                    <H4>Special Instructions</H4>
                    { instructions && <div style={{ fontSize: '0.9em', color: '#555' }}>{instructions}</div> }
                    <div style={{position: 'absolute', top: 3, right: 3}}>
                        <Button size='small' onClick={() => setOpenInstructionsDialog(true)}>EDIT</Button>
                    </div>
                </div>
            </BlueBox>
            {String(process.env.REACT_APP_USE_BEANS).toLowerCase() === 'true' &&
                <div style={{position: 'absolute', top: 3, right: 65}}>
                    <Button size='small' onClick={handleEnrich} disabled={loadingAddress}>ENRICH</Button>
                </div>
            }
            <div style={{position: 'absolute', top: 3, right: 3}}>
                <Button size='small' onClick={handleClickOpen}>EDIT</Button>
            </div>
            {String(process.env.REACT_APP_USE_BEANS).toLowerCase() === 'true' &&
                <div style={{position: 'absolute', top: 3, right: 138}}>
                    <Link to={`/beans/${shipmentId}`} target="_blank" disabled={loadingAddress}>
                        <Button size='small'>BEANS</Button>
                    </Link>
                </div>
            }
        </div>
        <Dialog
            maxWidth="md"
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <div style={{width: 800}}>
                    <AddressEditorContainer orig={corrected_address || address} onSave={correctAddress} onOverride={onAddressOverride}
                                            onClose={handleClose}/>
                </div>
            </DialogContent>
        </Dialog>
        <Dialog
            maxWidth="md"
            open={loadingAddress || loadingProfile}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <CircularProgress size={24}/>
                {loadingAddress && <div>Enriching address...</div>}
                {loadingProfile && <div>Saving profile...</div>}
            </DialogContent>
        </Dialog>
        <Dialog
            maxWidth="md"
            open={openInstructionsDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenInstructionsDialog(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Edit Special Instructions</DialogTitle>
            <DialogContent>
                <div style={{width: 480}}>
                    <TextField defaultValue={instructions} value={editingInstructions} fullWidth multiline maxRows={10} minRows={4} variant='outlined' onChange={(e) => setEditingInstructions(e.target.value)} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={saveInstrucions}>Save</Button>
                <Button onClick={() => setOpenInstructionsDialog(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            maxWidth="md"
            open={openVerifyLatLngOnOverrideDialog}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Verify Latitude and Longitude</DialogTitle>
            <DialogContent>
                <div style={{width: 480}}>
                   You have overridden the address. Please verify the GPS coordinates (latitude and longitude) are correct.
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpenVerifyLatLngOnOverrideDialog(false)}>OK</Button>
            </DialogActions>
        </Dialog>
    </Grid>
}

export default withStyles(styles)(CustomerProfileDetail);
