import React from 'react';


import { useRecoilValue } from 'recoil';

import { selectedShipmentInfoState } from '../../states/shipment';
import { BlueBox, RedBox } from '../../components/Box';
import { Grid, Typography } from '@material-ui/core';
import { H4 } from '../../components/Heading'
import CodeBloc from '../../components/CodeBloc';
import ShipmentPODContainer from '../ShipmentPODContainer';
import { selectedShipmentPicturesState } from '../../states/shipment';

export default function OutboundInfoContainer() {
    const info = useRecoilValue(selectedShipmentInfoState)
    const { assignment, pickup, dropoff, driver, has_customer_positive_feedback } = info || {}
    const pictures = useRecoilValue(selectedShipmentPicturesState)

    if (!assignment) return <></>
    return (
        <BlueBox>
            { has_customer_positive_feedback === false && 
                <div style={{paddingTop: 10, color: '#d0021b'}}>
                    <span style={{ display:'inline-flex', alignItems: 'center' }} className="legend-label">
                        <svg className="MuiSvgIcon-root jss715" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z"></path></svg>

                        <span style={{ margin: 5, color: '#d0021b', fontSize: '1.2em'}}>The customer has left a negative feedback</span>
                    </span>
                </div>
            }

            { has_customer_positive_feedback === true && 
                <div style={{paddingTop: 10, color: '#4abc4e'}}>
                    <span style={{ display:'inline-flex', alignItems: 'center' }} className="legend-label">
                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"></path></svg>

                        <span style={{ margin: 5, color: 'blue', fontSize: '1.2em'}}>The customer has left a positive feedback</span>
                    </span>
                </div>
            }

            <Grid container>
                <Grid item xs={12} md={5}>
                    <H4>Assignment</H4>
                    <Typography><CodeBloc style={{fontSize: 12}}>[{assignment.id}]</CodeBloc> { assignment.label }</Typography>
                    { driver && <>
                        <H4>Driver</H4>
                        <Typography><CodeBloc style={{fontSize: 12}}>[{driver.id}]</CodeBloc> { driver.first_name } {driver.last_name}</Typography>
                        <Typography><CodeBloc>{driver.phone_number}</CodeBloc></Typography>
                    </>}
                </Grid>
                <Grid item xs={12} md={7}>
                    { dropoff && <>
                    <H4>Dropoff</H4>
                    <Grid container>
                        <Grid item xs={6}><CodeBloc>{dropoff.status}</CodeBloc></Grid>
                        <Grid item xs={6}><CodeBloc>{dropoff.reason}</CodeBloc></Grid>
                        <Grid item xs={12}><Typography style={{color: '#666'}}>{dropoff.remark}</Typography></Grid>
                    </Grid>
                    </>}
                    {!dropoff && <RedBox>
                        WARNING: Dropoff stop not found
                        {pickup && <> <H4>Pickup</H4>
                            <div><CodeBloc>{pickup.status}</CodeBloc></div>
                        </>}
                    </RedBox>}
                    <ShipmentPODContainer pictures={pictures} />
                </Grid>
            </Grid>
        </BlueBox>
    );
}

