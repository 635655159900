import React from 'react';
import moment from 'moment';

import { Typography, Grid, Paper } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ClientLogoContainer from '../ClientLogo';
import { useGotoDetailPageCallback } from '../../states/incidents'

const styles = () => ({
    error: { border: "solid 1px #f4485e", borderLeft: 'solid 8px #f4485e', padding: 10, marginBottom: 6, borderRadius: 5 },
    success: { border: "solid 1px #75c31e", borderLeft: 'solid 8px #75c31e', padding: 10, marginBottom: 6, borderRadius: 5 },
    failed: { border: "solid 1px #4a4a4a", borderLeft: 'solid 8px #4a4a4a', padding: 10, marginBottom: 6, borderRadius: 5 },
    item: {
        fontSize: '15px',
        padding: '2px',
        color: '#5a5a5a',
        textDecoration: 'none',
        cursor: 'pointer'
    }
});

function IncidentListItem({incident, classes, base}) {
    const openDetail = useGotoDetailPageCallback()
    const cls = incident.status === 'CREATED' ? classes.error : incident.status === 'RESOLVED' ? classes.success : classes.failed
    return <Paper className={cls} p={3} elevation={1} onClick={() => openDetail(incident.id)}>
        <Grid container spacing={4}>
            <Grid item p={2} xs={3} md={2} lg={1}>
                <Typography className={classes.item}>{incident.object.split('_')[1]}</Typography>
            </Grid>
            <Grid item p={2} xs={2} md={1}>
                <div style={{marginTop: -6, marginBottom: -6}}><ClientLogoContainer size={32} id={incident?.shipment?.client_id} /></div>
            </Grid>
            <Grid item p={2} xs={2} md={2}>
                <Typography className={classes.item}>{ moment(incident._created).format('MM/DD hh:mmA') }</Typography>
            </Grid>
            <Grid item p={2} xs={2} md={1}>
                <Typography className={classes.item}>{ incident.delivery_date ? moment(incident.delivery_date).format('MM/DD/YY'): "N/A" }</Typography>
            </Grid>
            <Grid item p={2} xs={3}>
                <Typography className={classes.item}>{incident.shipment?.dropoff_address?.street}</Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography className={classes.item}>{incident?.shipment?.dropoff_address?.city}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.item}>{incident?.shipment?.dropoff_address?.state} {incident?.shipment?.dropoff_address.zipcode}</Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography className={classes.item}>{incident?.priority}</Typography>
            </Grid>
        </Grid>
    </Paper>
}

export default withStyles(styles)(IncidentListItem);
