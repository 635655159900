import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { H2 } from "../../components/Heading";
import {TextField, FormControlLabel, Checkbox, Box, CircularProgress} from "@material-ui/core";
import { useResolveCallback, incidentDetailState, useGotoListPageCallback } from '../../states/incidents';
import { useEditAddressLocationCallback, useUpdateAddressCallback, selectedAddressState } from '../../states/address';
import { useRecoilValue } from "recoil";
import {isNumeric} from "../../utils/numbers";
import toast from "react-hot-toast";


export default function EditAddressLocationDialog({open, onClose, suggested, origin, shouldUncharted, classes, shipmentId}) {
    const editAddressLocation = useEditAddressLocationCallback()
    const updateAddress = useUpdateAddressCallback()
    const [latlng, setLatlng] = useState(suggested || '')
    const [ uncharted, setUncharted ] = useState(shouldUncharted || false)
    const [ pinVerified, setPinVerified ] = useState(false)
    const selectedAddress = useRecoilValue(selectedAddressState)
    const selectedIncident = useRecoilValue(incidentDetailState);
    const resolve = useResolveCallback();
    const gotoList = useGotoListPageCallback()
    const [error, setError] = useState('');
    const [ loading, setLoading] = useState(false)
    
    useEffect(() => {
        setPinVerified(selectedAddress.pin_verified || false)
    }, [selectedAddress])

    useEffect(() => {
        setLatlng(suggested || '')
    }, [suggested])

    const saveManual = (override) => {
        if (latlng !== origin) {
          const newLatLng = latlng.split(',').map(x => parseFloat(x))
          if(!isNumeric(newLatLng[0]) || !isNumeric(newLatLng[1])){
              setError("Please enter valid latitude and longitude values")
          }else {
              setError('')
              if(override === undefined){
                  override = false
              }
              setLoading(true)
              editAddressLocation(newLatLng[0], newLatLng[1], {uncharted, pin_verified: pinVerified},override, shipmentId).then(response => {
                  setLoading(false)
                  if(response && response.code && response.code != 200){
                      setError(response.message)
                      return;
                  }
                  toast.success("Successfully updated location")
                  if (typeof onClose === 'function') onClose();
                  if (selectedIncident && selectedIncident.type !== 'WRONG-DROPOFF-LOCATION') {
                      resolve('RESOLVED').then(() => {
                          gotoList();
                      });
                  }

              })
          }
        } else {
            setLoading(true)
            updateAddress({uncharted, pin_verified: pinVerified}, shipmentId).then(() => {
            setLoading(false)
            if (typeof onClose === 'function') onClose();
            if (selectedIncident && selectedIncident.type !== 'WRONG-DROPOFF-LOCATION') {
              resolve('RESOLVED').then(() => {
                gotoList();
              });
            }
          })
        }
    }

    const updateUncharted = (e, b) => {
        setUncharted(b)
    }

    const updatePinVerified = (e, b) => {
        setPinVerified(b)
    }

    const handleClose = () => {
      onClose()
      setPinVerified(selectedAddress.pin_verified || false)
      setUncharted(shouldUncharted || false)
      setLatlng(suggested || '')
      setError('')
    }


    return <Dialog
        maxWidth="md"
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={ onClose }
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>
            <H2>Manual Geocode</H2>
        </DialogTitle>
        <DialogContent>
            <Typography style={{ marginBottom: 20 }}>Please provide GPS location in the format: <code>latitude, longitude</code></Typography>
            <TextField variant='outlined' fullWidth value={latlng} onChange={(e) => setLatlng(e.target.value)} />
            {error &&
                <Box className={classes.error} p={2}>
                    <Grid container>
                        <Grid item md={12}>
                            <div fontSize="small" style={{verticalAlign: 'top'}} />
                            <Box component="span" px={0.5}>{error}</Box>
                        </Grid>
                        {!error.includes('Please enter valid latitude and longitude values') &&
                            <Grid item md={12}>
                                <Button style={{background: 'red'}} onClick={() => saveManual(true)}>Click to
                                    override</Button>
                            </Grid>
                        }
                    </Grid>
                </Box>
            }
            <Grid container>
                <Grid item xs={6}>
                    <div style={{paddingTop: 10}}>
                        <div style={{width: 140}}><FormControlLabel control={<Checkbox checked={uncharted} onChange={ updateUncharted } />} label="Un-Charted" /></div>
                        <div style={{flex: 1}}><Typography style={{color: '#888', fontSize: '0.8em'}}>Use GPS location for navigation</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{paddingTop: 10}}>
                        <div style={{width: 140}}><FormControlLabel control={<Checkbox checked={pinVerified} onChange={ updatePinVerified } />} label="Verified Pin" /></div>
                        <div style={{flex: 1}}><Typography style={{color: '#888', fontSize: '0.8em'}}>Dropoff pin is <strong>accurate</strong></Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>

        </DialogContent>
        <DialogActions>
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{flex: 1, textAlign: 'center'}}><Button disabled={loading} onClick={()=>saveManual(false)}>{loading ? <CircularProgress color="primary" size={20} /> : 'Save and Resolve'}</Button></div>
                <div style={{flex: 1, textAlign: 'center'}}><Button disabled={loading} onClick={handleClose}>Close</Button></div>
            </div>
        </DialogActions>
    </Dialog>
}
