const incidentTypeList = [
    {label: 'GEOCODE FAILED', type: 'geocode-failed'},
    {label: 'WRONG DROP-OFF LOCATION', type: 'wrong-dropoff-location'},
    {label: 'ADDRESS-INVALID', type: 'address-invalid'},
    {label: 'ADDRESS-INVALID-DAS', type: 'address-invalid-das'},
    {label: 'Address Corrected', type: 'address-corrected'},
    {label: 'Address Missing or Invalid Unit', type: 'address-missing-unit'},
    {label: 'Customer Profile', type: 'customer-profile'},
    {label: 'First Shipment', type: 'first-shipment'},
    {label: 'Thumbs Down', type: 'thumbs-down'},
    {label: 'MDU No Access Code', type: 'address-mdu-no-access-code'},
    {label: 'BUSINESS CLOSED', type: 'BUSINESS-CLOSED'},
    {label: 'CUSTOMER INITIATED', type: 'CUSTOMER-INITIATED'},
    {label: 'LOST BY DRIVER', type: 'LOST-BY-DRIVER'},
    {label: 'DAMAGED BOX', type: 'DAMAGED-BOX'},
];
export default incidentTypeList;