import { create_api } from "./base";

export default class RegionRepo {
  baseUrl = "/regions";
  constructor() {
    this.api = create_api(process.env.REACT_APP_API_ROOT);
  }

  list = () => this.api.get(`${this.baseUrl}`).then(r => r.data)
}
