import React from 'react';

import { Typography, Grid } from "@material-ui/core";
import Directions from '@material-ui/icons/Directions';

import moment from 'moment';

export default function HistoricalShipment({shipment, showAddress, onClick, client, showCustomer}) {
    return (
            <Grid container direction='row' alignItems='stretch'>
                <Grid container item xs={9} md={9} style={{padding: 5}}>
                    <Grid item xs={4}>
                        <Typography>{shipment.id} <a target='_blank' rel="noreferrer" href={`/shipment/${shipment.id}`}><Directions fontSize='small' color='disabled' style={{marginBottom: -4 }} /></a></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={{fontSize: 16, color: '#222'}}>{moment(shipment.dropoff_earliest_ts).format('MMM DD, YYYY')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography>
                        {shipment.status.replaceAll('_', ' ')}
                        { shipment.inbound_status && <code> | {shipment.inbound_status}</code>}
                    </Typography>
                    { showCustomer && <Typography style={{fontSize: '0.9em', color: '#222'}}>{shipment.customer.name}</Typography>}
                    { showAddress && <Typography style={{fontSize: '0.8em', color: '#222'}}>{ shipment.dropoff_address.street} { shipment.dropoff_address.street2}, { shipment.dropoff_address.city}, { shipment.dropoff_address.state} { shipment.dropoff_address.zipcode}</Typography> }
                    </Grid>
                </Grid>
                <Grid item onClick={onClick} xs={3} md={3} container sx={{ height: '100%', cursor: 'pointer' }}>
                    <div style={{height: 'calc(100%)', maxHeight: 'calc(100%)', cursor: 'pointer'  }}>
                    { client && client.logo_url && <img alt={client.company} style={{maxWidth: 32, maxHeight: 32}} src={client.logo_url} title={client.company} />}
                    { client && !client.logo_url && <Typography>{client.company}</Typography>}
                    { !client && <Typography>{shipment.client_id}</Typography>}
                    </div>
                </Grid>
            </Grid>
    );
}
