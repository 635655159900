import React, {useState, useEffect} from 'react';

import { Container, FormControlLabel, Grid, Checkbox, Divider } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { selectedAddressState, verifiedPhotoState } from '../../states/address';
import { useRecoilValue } from 'recoil';
import { useRelocateAddressCallback, useUpdateAddressCallback, useAddAccessCodeCallback, useVerifyAddressCallBack } from '../../states/address';
import { useResolveCallback, incidentDetailState, useGotoListPageCallback } from '../../states/incidents';

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { H4, Label } from '../../components/Heading';
import { BlueBox, ColorBox } from '../../components/Box';
import Dev from '../Dev';
import GpsLocation from '../GpsLocation';
import EditAddressLocationDialog from '../EditAddressLocationDialog'
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide, TextField } from '@material-ui/core';
import Verify from './verify';


const styles = () => ({
    error: { border: "solid 1px #f4485e", borderLeft: 'solid 1px #f4485e', padding: 10, borderRadius: 5 },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function AddressDetail({classes, shipment}) {
    const address = useRecoilValue(selectedAddressState)
    const [open, setOpen] = useState(false)
    const relocateAddress = useRelocateAddressCallback()
    const verifyAddress = useVerifyAddressCallBack()
    const updateAddress = useUpdateAddressCallback()
    const addAccessCode = useAddAccessCodeCallback()
    const [ uncharted, setUncharted ] = useState(false)
    const [ pinVerified, setPinVerified ] = useState(false)
    const [ suggested, setSuggested ] = useState()
    const [ editingAccessCode, setEditingAccessCode ] = useState('')
    const [ openEditingAccessCode, setOpenEditingAccessCode ] = useState(false)
    const verifiedPhoto = useRecoilValue(verifiedPhotoState)
    const selectedIncident = useRecoilValue(incidentDetailState);
    const resolve = useResolveCallback();
    const gotoList = useGotoListPageCallback()
    useEffect(() => {
        setUncharted((address && address.uncharted) || false)
        setPinVerified((address && address.pin_verified) || false)
    }, [address])

    const relocate = () => {
        relocateAddress()
    }

    const verifyPin = () => {
        verifyAddress()
    }

    const updateUncharted = (e, b) => {
        setUncharted(b)
        updateAddress({uncharted: b})
    }

    const updatePinVerified = (e, b) => {
        setPinVerified(b)
        updateAddress({ pin_verified: b, photos_verified: verifiedPhoto, shipment_id: shipment && shipment.id})
    }

    const openEditDialog = () => {
        setSuggested(address && address.location && address.location.latitude ? `${address.location.latitude},${address.location.longitude}` : null)
        setOpen(true)
    }

    const openEditAccessCode = () => {
        setEditingAccessCode('')
        setOpenEditingAccessCode(true)
    }
    const saveAccessCode = () => {
      if (editingAccessCode) {
        addAccessCode(editingAccessCode, shipment && shipment.id);
        if (selectedIncident && selectedIncident.type !== 'WRONG-DROPOFF-LOCATION') {
          resolve('RESOLVED').then(() => {
            gotoList();
          });
        }
      }
      setOpenEditingAccessCode(false)
    }

    const accuracyColor = (s) => s === 'UNLOCATABLE' ? '#f34' : s === 'MANUAL' ? '#34F' : '#3f4'

    if (!address) return <div></div>
    return <BlueBox>
            <Container style={{padding: 0}}>
                <Dev><Typography style={{fontSize: '0.8em'}}>{address.id}</Typography></Dev>
                <div style={{display: 'flex', justifyContent: 'space-between', position: 'relative'}}>
                <div style={{paddingTop: 5}}>{ address.accuracy && <code style={{color: accuracyColor(address.accuracy)}}>{address.accuracy}</code>}</div>
                <div style={{position: 'absolute', top: 0, right: 0}}>
                { address.accuracy === 'UNLOCATABLE' &&
                    <Dev><Button size='small' onClick={relocate}>relocate</Button></Dev>
                }
                    <Button size='small' onClick={openEditDialog}>edit</Button>

                </div>
                </div>
                <EditAddressLocationDialog open={open} onClose={() => setOpen(false)} suggested={ suggested } origin={suggested} shouldUncharted={address.uncharted} classes={classes} shipmentId={shipment && shipment.id}/>
            </Container>
            { address.geocoding_addresses && <Dev><Typography style={{fontSize: '0.8em'}}>Geocoding address: { address.geocoding_addresses.join(",") }</Typography></Dev> }
            { address.location && <Container style={{padding: 0}}>
                <GpsLocation lat={address.location.latitude} lng={address.location.longitude} google={true} />
                <Grid container>
                    <Grid item xs={6}>
                        <div style={{paddingTop: 10}}>
                            <div style={{width: 140}}><FormControlLabel control={<Checkbox checked={uncharted} />} label="Un-Charted" /></div>
                            <div style={{flex: 1}}><Typography style={{color: '#888', fontSize: '0.8em'}}>Use GPS location for navigation</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{paddingTop: 10}}>
                            <div style={{width: 140}}><FormControlLabel control={<Checkbox checked={pinVerified} onChange={ updatePinVerified } />} label="Verified Pin" /></div>
                            <div style={{flex: 1}}><Typography style={{color: '#888', fontSize: '0.8em'}}>Dropoff pin is <strong>accurate</strong></Typography>
                            </div>
                            <Dev>
                                { !pinVerified && <div>
                                    <Button size='small' onClick={verifyPin}>Verify</Button>
                                </div> }
                            </Dev>
                        </div>
                    </Grid>
                </Grid>
            </Container> }

            <Verify address={address}></Verify>

            <Divider style={{marginTop: 10}} />
            <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: 3, right: 3}}>
                    <Button size='small' onClick={openEditAccessCode}>ADD</Button>
                </div>
                <H4>Access Code: { address.access_code && <code>{ address.access_code }</code> }</H4>
                { address.known_access_codes && <ColorBox>
                    <Grid container>
                        <Grid item xs={5}><H4>Date</H4></Grid>
                        <Grid item xs={4}><H4>Code</H4></Grid>
                        <Grid item xs={3}><H4>Source</H4></Grid>
                    </Grid>
                    { address.known_access_codes.map(code => <Grid container key={code.created}>
                        <Grid item xs={5} style={{fontSize: '0.9em'}}>{ moment(code.created).format('MM/DD/YY HH:mm') }</Grid>
                        <Grid item xs={4}>{ code.code }</Grid>
                        <Grid item xs={3}>{ code.source }</Grid>
                    </Grid>)}
                </ColorBox>}
                <Dialog
                    maxWidth="md"
                    open={openEditingAccessCode}
                    // TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenEditingAccessCode(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>Add Access Code</DialogTitle>
                    <DialogContent>
                        <div style={{width: 480}}>
                            <TextField placeholder='Access Code' value={editingAccessCode} fullWidth variant='outlined' onChange={(e) => setEditingAccessCode(e.target.value || '')} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={saveAccessCode}>Save And Resolve</Button>
                        <Button onClick={() => setOpenEditingAccessCode(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
    </BlueBox>
}

export default withStyles(styles)(AddressDetail);
