import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography, Container, Grid, LinearProgress, Select, MenuItem, FormControl, InputLabel, CircularProgress, TextField } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';
import moment from 'moment';
import { debounce, set } from 'lodash';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { incidentListSelector, useUpdateFilterCallback, useUpdatePageCallback, useLoadFilterCallback, incidentTagsState, useUpdateTagCallback, selectedIncidentIdState } from '../../states/incidents';
import { selectedHistoricalDeliveryIdsState, useSelectShipmentCallback } from '../../states/shipment'
import { allClientsSelector } from '../../states/client';
import { allRegionSelector } from '../../states/region';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useParams, useLocation } from 'react-router-dom';

import IncidentListItem from './list_item'
import { Pagination } from '@material-ui/lab';
import Button from "@material-ui/core/Button";
import {useNavigate} from "react-router";
import incidentTypeList from "../../constants/incidentTypeList";



function IncidentListInner({ base }) {
    const incidentList = useRecoilValue(incidentListSelector)
    const updatePage = useUpdatePageCallback()

    const gotoPage = (p) => {
        updatePage(parseInt(p))
    }

    return (
        <Container style={{ marginBottom: 20, padding: 0 }}>
            <Box
                sx={{
                    p: 1,
                    bgcolor: '#f0f0f0',
                    display: 'grid',
                    gridTemplateColumns: { md: '1fr' },
                    gap: 3,
                }}
            >
                <Grid container spacing={4}>
                    <Grid item p={2} xs={3} md={2} lg={1}>
                        <Typography style={{ fontSize: 14, color: '#9b9b9b', fontWeight: '600' }}>SHIPMENT</Typography>
                    </Grid>
                    <Grid item p={2} xs={2} md={1}>
                        <Typography style={{ fontSize: 14, color: '#9b9b9b', fontWeight: '600' }}>CLIENT</Typography>
                    </Grid>
                    <Grid item p={2} xs={2} md={2}>
                        <Typography style={{ fontSize: 14, color: '#9b9b9b', fontWeight: '600' }}>CREATED</Typography>
                    </Grid>
                    <Grid item p={2} xs={2} md={1}>
                        <Typography style={{ fontSize: 14, color: '#9b9b9b', fontWeight: '600' }}>DELIVERY</Typography>
                    </Grid>
                    <Grid item p={2} xs={3}>
                        <Typography style={{ fontSize: 14, color: '#9b9b9b', fontWeight: '600' }}>STREET</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{ fontSize: 14, color: '#9b9b9b', fontWeight: '600' }}>CITY</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{ fontSize: 14, color: '#9b9b9b', fontWeight: '600' }}>STATE</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{ fontSize: 14, color: '#9b9b9b', fontWeight: '600' }}>PRIORITY</Typography>
                    </Grid>
                </Grid>

                {incidentList.items.map(item => <IncidentListItem base={base} key={item.id} incident={item} />)}
            </Box>
            <Pagination showFirstButton size='small' color="primary" count={Math.floor((incidentList.total + incidentList.limit - 1) / incidentList.limit)} page={1 + incidentList.offset / incidentList.limit} onChange={(e, p) => gotoPage(p)} />
        </Container>
    )
}

function ClientFilter() {
    const allClient = useRecoilValue(allClientsSelector)
    const [ client, setClient ] = useState('ALL')
    const incidentTags = useRecoilValue(incidentTagsState)
    const updateIncidentTags = useUpdateTagCallback()
    const updateClientFilter = (e) => {
        const v = e.target.value
        setClient(v)
        updateIncidentTags({client: v === 'ALL' ? [] : [v]})
    }

    useEffect(() => {
        const tag = (!incidentTags || !incidentTags.client || incidentTags.client.length < 1) ? 'ALL' : incidentTags.client[0]
        setClient(tag)
    }, [incidentTags])


    return <FormControl style={{ marginRight: 20 }}>
        <InputLabel id="incident-select-client">Client</InputLabel>
        <Select
            value={client}
            label="Client"
            inputProps={{
                name: 'client',
                id: 'incident-client',
            }}
            onChange={updateClientFilter}
        >
            <MenuItem value={'ALL'}>ALL</MenuItem>
            { allClient && allClient.map(client => <MenuItem key={client.id} value={`CL_${client.id}`}>{client.company}</MenuItem>)}
        </Select>
    </FormControl>

}

function RegionFilter() {
    const allRegion = useRecoilValue(allRegionSelector);
    const [ region, setRegion ] = useState('ALL')
    const incidentTags = useRecoilValue(incidentTagsState)
    const updateIncidentTags = useUpdateTagCallback()
    const updateRegionFilter = (e) => {
        const v = e.target.value
        setRegion(v)
        updateIncidentTags({region: v === 'ALL' ? [] : [v]})
    }

    useEffect(() => {
        const tag = (!incidentTags || !incidentTags.region || incidentTags.region.length < 1) ? 'ALL' : incidentTags.region[0]
        setRegion(tag)
    }, [incidentTags])


    return <FormControl style={{ marginRight: 20 }}>
        <InputLabel id="incident-select-client">Region</InputLabel>
        <Select
            value={region}
            label="Region"
            inputProps={{
                name: 'region',
                id: 'incident-region',
            }}
            onChange={updateRegionFilter}
        >
            <MenuItem value={'ALL'}>ALL</MenuItem>
            { allRegion && allRegion.map(region => <MenuItem key={region.label} value={`RG_${region.value}`}>{region.label} ({region.value})</MenuItem>)}
        </Select>
    </FormControl>

}

export default function IncidentList() {
    const params = useParams()
    const location = useLocation()
    const selectShipment = useSelectShipmentCallback()
    const setSelectedHistory = useSetRecoilState(selectedHistoricalDeliveryIdsState)
    const updateFilter = useUpdateFilterCallback()
    const loadFilter = useLoadFilterCallback()
    const [tags, setTags] = useState('ALL')
    const incidentTags = useRecoilValue(incidentTagsState)
    const updateIncidentTags = useUpdateTagCallback()
    const setSelectedIncidentId = useSetRecoilState(selectedIncidentIdState)
    const queryParams = new URLSearchParams(location.search || '');
    const [datePicker, setDatePicker] = useState(queryParams.get('date'));
    const [ incidentType, setType ] = useState(params.type ? params.type.toUpperCase() : 'GEOCODE-FAILED')
    const [ status, setStatus ] = useState(queryParams.get('status')|| 'CREATED')
    const [openPicker, setOpenPicker] = useState(false);
    const navigate = useNavigate();


    const [ searchInput, setSearchInput ] = useState('');

    const debounceSearchInput = useCallback(debounce((value) => updateIncidentTags({search: value}), 1000), []);

    const updateStatusFilter = (e) => {
        setStatus(e.target.value)
        updateFilter({status: e.target.value})
    }

    const updateTypeFilter = (e) => {
        setType(e.target.value)
        updateFilter({type: e.target.value})
    }

    const updateDPTags = (e,v) => {
        if (v!=null) {
            setTags(v)
            updateIncidentTags({dp: v === 'ALL' ? [] : [v]})
        }
    }

    const updateSearchInput = (v) => {
        setSearchInput(v);
        debounceSearchInput( v ? [`Search_${v}`] : [])
    }

    useEffect(() => {
        loadFilter()
        setSelectedHistory([])
        selectShipment(null)
        setSelectedIncidentId(null)
    }, [])

    useEffect(() => {
        const tag = (!incidentTags || !incidentTags.dp || incidentTags.dp.length < 1) ? 'ALL' : incidentTags.dp.indexOf('DSP') >= 0 ? 'DSP' : 'DP';
        const searchInput = (!incidentTags || !incidentTags.search || incidentTags.search.length < 1) ? '' : incidentTags.search[0].split('Search_')[1];
        setSearchInput(searchInput);
        setTags(tag)
    }, [incidentTags])

    const handleChangeDate = (date) => {
      const dateFormat = moment(date).format('yyyy-MM-DD')
      let dataDate = dateFormat;
      if (datePicker == dateFormat) {
        dataDate = undefined;
      }

      setDatePicker(dataDate)
      updateFilter({date: dataDate})
    }

    const goToLandingPage = () => {
        navigate(`/`)
    }

    return (
        <Container style={{ height: 'calc(100wh - 65px)' }}>

            <Container maxWidth='xl' style={{padding: 15, display: 'flex', justifyContent: 'space-between'}}>
                <Button variant='outlined' onClick={ ()=>{goToLandingPage()} }>Back to Main</Button>
            </Container>
            <div style={{ padding: 15 }}>
                <span style={{ display: 'inline-block', fontSize: '1.2em', padding: '16px 10px 10px 0px', color: '#4a4a4a' }}>Overview for {incidentType}</span>
                <FormControl style={{ marginRight: 20 }}>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            open={openPicker}
                            onOpen={() => setOpenPicker(true)}
                            onClose={() => setOpenPicker(false)}
                            disableToolbar
                            showTodayButton={true}
                            size="small"
                            label="Date"
                            className=''
                            InputProps={{id:"incident-select-date", readOnly: true, onClick: () => setOpenPicker(true) }}
                            inputProps={{ style: { cursor: 'pointer' } }}
                            keyboardIcon={<ArrowDropDownIcon />}
                            variant="inline"
                            format="YYYY-MM-DD"
                            value={datePicker}
                            onChange={(date) => handleChangeDate(date)}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl style={{ marginRight: 20 }}>
                    <InputLabel id="incident-select-type">Type</InputLabel>
                    <Select
                        value={incidentType}
                        label="Type"
                        inputProps={{
                            name: 'type',
                            id: 'incident-type',
                        }}
                        onChange={updateTypeFilter}
                    >
                        {incidentTypeList.map(incident =>
                            <MenuItem key={incident.type.toUpperCase()} value={incident.type.toUpperCase()}>{incident.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl style={{ marginRight: 20 }}>
                    <InputLabel id="incident-select-status">Status</InputLabel>
                    <Select
                        value={status}
                        label="Status"
                        inputProps={{
                            name: 'status',
                            id: 'incident-status',
                        }}
                        onChange={updateStatusFilter}
                    >
                        <MenuItem value={'CREATED'} style={{ color: '#f4485e' }}>Unresolved</MenuItem>
                        <MenuItem value={'DISPATCH_FOLLOWUP'}>Dispatch Follow-up Required</MenuItem>
                        <MenuItem value={'UNSOLVABLE'}>UnSolvable</MenuItem>
                        <MenuItem value={'RESOLVED'} style={{ color: '#75c31e' }}>Resolved</MenuItem>
                    </Select>
                </FormControl>
                <React.Suspense fallback={<CircularProgress />}>
                    <ClientFilter />
                </React.Suspense>
                <React.Suspense fallback={<CircularProgress />}>
                    <RegionFilter />
                </React.Suspense>
                <ToggleButtonGroup
                    color="primary"
                    value={tags}
                    exclusive
                    onChange={updateDPTags}
                    size='small'
                    style={{ marginRight: 20 }}
                    >
                    <ToggleButton value="ALL">ALL</ToggleButton>
                    <ToggleButton value="DSP">DSP</ToggleButton>
                    <ToggleButton value="DP">IC</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <Box px={1}>
                <FormControl style={{verticalAlign: 'bottom', width: '50%'}}>
                    <TextField  size='small' placeholder='Shipment ID, Tracking code, Customer name, Address' value={searchInput} fullWidth variant='outlined' onChange={(e) => updateSearchInput(e.target.value)} />
                </FormControl>
            </Box>
            <Box py={2} px={1}>
                <React.Suspense fallback={<LinearProgress />}>
                    <IncidentListInner base={`/incident/${params.type}`} />
                </React.Suspense>
            </Box>
        </Container>
    );
}
