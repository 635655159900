import React, { useEffect, useState } from "react";
import GpsLocation from "../GpsLocation";
import { useRecoilValue } from "recoil";
import { selectedAddressState } from "../../states/address";
import { CheckBox } from "@material-ui/icons";

import EditAddressLocationDialog from "../EditAddressLocationDialog";


export default function SuggestableGpsLocation({lat,lng,...options}) {
    const address = useRecoilValue(selectedAddressState)
    const [open, setOpen] = useState(false)
    const [ origin, setOrigin ] = useState()
    useEffect(() => {
        setOrigin(address && address.location ? `${address.location.latitude},${address.location.longitude}` : null)
    }, [address])
    return <>
        <GpsLocation lat={lat} lng={lng} {...options}>
            {address && <span style={{marginLeft: 4, padding: '2px 2px 3px 4px', display: 'inline-block', margin: 0}}><CheckBox onClick={(e) => {setOpen(true); e.stopPropagation()}} fontSize='small' style={{marginBottom: -6}} color='disabled' /></span>}
        </GpsLocation>
        { address && <EditAddressLocationDialog open={open} onClose={() => setOpen(false)} suggested={`${lat},${lng}`} origin={origin} shouldUncharted={true} />}
    </>
}