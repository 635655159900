import React, { useRef, useState } from 'react';

import { Grid, Paper, Tab, Tabs, Box, LinearProgress, Container } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import AddressLocator from '../AddressLocator'
import DeliverySearchContainer from '../DeliverySearch';
import ShipmentDetailContainer from '../Shipment';
import DeliveryHistory from '../DeliveryHistory';
import WrongDropoffLocationContainer from '../WrongDropoffLocationContainer'
import ShipmentEventsContainer from '../ShipmentEventsContainer'
import OutboundInfoContainer from '../OutboundInfo'
import { selectedIncidentIdState, incidentDetailState } from '../../states/incidents';
import { useRecoilValue } from 'recoil';
import NoteContainer from '../Note';
import AddressHistoryContainer from '../AddressHistory'

const styles = () => ({
    error: { border: "solid 1px #f4485e", borderLeft: 'solid 8px #f4485e', padding: 10, marginBottom: 6, borderRadius: 5 },
    success: { border: "solid 2px #75c31e", borderLeft: 'solid 8px #75c31e', padding: 10, marginBottom: 6, borderRadius: 5 },
    failed: { border: "solid 2px #4a4a4a", borderLeft: 'solid 8px #4a4a4a', padding: 10, marginBottom: 6, borderRadius: 5 },
    normal: { padding: 15, minHeight: 'calc(100vh - 250px)', width: '100%' },
    item: {
        fontSize: '15px',
        padding: '2px',
        color: '#5a5a5a',
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{height: 'calc(100% - 75px)', overflow: 'auto'}}
      >
        {value === index && (<>{children}</>)}
      </div>
    );
  }

function ShipmentProfile({classes}) {
    const [searchTab, setSearchTab] = useState(0)
    const incidentId = useRecoilValue(selectedIncidentIdState)
    const selectedIncident = useRecoilValue(incidentDetailState)
    const handleChange = (event, newValue) => {
        setSearchTab(newValue);
    };
    
    return (<Grid container spacing={2}>
        <Grid item container xs={12} md={6} lg={4}>
            <React.Suspense fallback={<LinearProgress />}>
                <ShipmentDetailContainer />
            </React.Suspense>
        </Grid>
        <Grid item container xs={12} md={6} lg={4}>
            <Paper elevation={2} style={{width: '100%'}}>
                <div style={{display: 'flex', height: '100%', flexDirection: 'column', padding: '15px'}}>
                    {selectedIncident && <code>Priority: <b>{selectedIncident.priority}</b></code>}
                    { incidentId && <NoteContainer uid={`ICD_${incidentId}`} /> }
                    <WrongDropoffLocationContainer />
                    <React.Suspense fallback={<LinearProgress />}>
                        <OutboundInfoContainer />
                    </React.Suspense>
                <div style={{flex: 1}}>
                <Container style={{padding: 0, height: '100%', maxHeight: '100%'}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 10 }}>
                        <Tabs value={searchTab} onChange={ handleChange } aria-label="Delivery History Tab" variant="scrollable">
                            <Tab style={{minWidth: '100px'}} label="History" id='tab-address-history' />
                            <Tab style={{minWidth: '100px'}} label="Events" id='tab-events' />
                            <Tab style={{minWidth: '100px'}} label="Address History" id='address-history' />
                            <Tab style={{minWidth: '100px'}} label="Search" id='tab-custom-search' />
                        </Tabs>
                    </Box>

                    <TabPanel value={searchTab} index={0}>
                        <React.Suspense fallback={<LinearProgress />}>
                            <DeliveryHistory />
                        </React.Suspense>
                    </TabPanel>
                    <TabPanel value={searchTab} index={1}>
                        <React.Suspense fallback={<LinearProgress />}>
                            <ShipmentEventsContainer />
                        </React.Suspense>
                    </TabPanel>
                    <TabPanel value={searchTab} index={2}>
                        <AddressHistoryContainer />
                    </TabPanel>
                    <TabPanel value={searchTab} index={3}>
                        <React.Suspense fallback={<LinearProgress />}>
                            <DeliverySearchContainer />
                        </React.Suspense>
                    </TabPanel>
                    
                </Container>
                    </div>
                </div>
            </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={4} style={{minHeight: 400}}>
            <React.Suspense fallback={<LinearProgress />}>
                <AddressLocator />
            </React.Suspense>
        </Grid>
    </Grid>)
}

export default withStyles(styles)(ShipmentProfile);
