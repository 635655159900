import React from 'react';

import { useToggleHistorySelectionCallback, isSelectedHistoricalDeliveryState, historicalDeliveriesState } from '../../states/shipment';
import { useRecoilValue } from 'recoil';
import { clientsSelector } from '../../states/client';
import { Grid, LinearProgress, Paper, Typography } from '@material-ui/core';

import HistoricalShipment from '../../components/HistoricalShipment';
import ShipmentPODContainer from '../ShipmentPODContainer';
import { H4 } from '../../components/Heading';
import { EventBloc } from '../ShipmentEventsContainer'
import { GrayBox } from '../../components/Box';
import CodeBloc from '../../components/CodeBloc';

export function ShipmentHistoryDetail({id}) {
    const info = useRecoilValue(historicalDeliveriesState(id))
    if (!id || !info) return <></>
    const { pods, events, dropoff, driver, assignment, label } = info || {}
    const pictures = pods ? pods.filter(p => p.type === 'picture') : []
    const shown = events ? events
        .filter(e => e.object.attributes && e.object.attributes.type === 'DROP_OFF' && e.subject.uid.indexOf('DR_') >= 0)
        .filter(e => e.action !== 'update_dropoff' || e.state.status === 'SUCCEEDED')
        : []
    return <>
        <Grid container spacing={1}>
            {pictures && pictures.length > 0 && <Grid item xs={5}>
                <ShipmentPODContainer pictures={pictures} />
            </Grid>}
            <Grid item xs={7}>
                { dropoff && <div>
                    <Grid container>
                        <Grid item xs={6}><H4 style={{paddingTop: 0}}>Dropoff</H4></Grid>
                        <Grid item xs={6}><H4 style={{paddingTop: 0}}>Assignment</H4></Grid>
                        <Grid item xs={6}>
                            <div><CodeBloc style={{fontSize: '0.9em'}}>{dropoff.status}</CodeBloc></div>
                            <div><CodeBloc>{dropoff.reason}</CodeBloc></div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>{assignment && <CodeBloc style={{fontSize: '0.8em'}}>{assignment.id}</CodeBloc> }</div>
                            <div>{label && <CodeBloc>{label.driver_label}</CodeBloc> }</div>
                        </Grid>
                    </Grid>
                </div>}
                { driver && <div>
                    <H4>Driver</H4>
                    <Grid container>
                        <Grid item xs={6}><Typography sx={{fontSize: 12}}>{driver.first_name} {driver.last_name}</Typography></Grid>
                        <Grid item xs={6}><CodeBloc style={{fontSize: 13}}>{driver.phone_number}</CodeBloc></Grid>
                    </Grid>
                </div>}
            </Grid>
        </Grid>
        <GrayBox>
        <EventBloc events={shown} />
        </GrayBox>
    </>
}

export default function DeliveryHistoryItem({shipment, ...options}) {
    const selectHistory = useToggleHistorySelectionCallback()
    const selected = useRecoilValue(isSelectedHistoricalDeliveryState(shipment.id))
    const client = useRecoilValue(clientsSelector(shipment.client_id))
    const borderColor = (!shipment.status || shipment.status.indexOf('CANCELLED') >= 0) ? '#4a4a4a' : shipment.status.indexOf('FAILED') > 0 ? '#f4485e' : '#75c31e'
    return <Paper elevation={1} variant='outlined' style={{ marginBottom: 5, width: '100%', borderColor, position: 'relative', ...(selected ? { borderRight: `solid 5px ${borderColor}` } : {borderRight: `solid 1px ${borderColor}`})}} >
        <HistoricalShipment onClick={ () => selectHistory(shipment) } {...options} client={client} shipment={shipment} />
        { selected && <React.Suspense fallback={<LinearProgress />}><ShipmentHistoryDetail id={shipment.id} /></React.Suspense>}
    </Paper>
}
