import { create } from 'apisauce';

export const create_api = (baseURL) => {
    const api = create({
        baseURL,
        withCredentials: true,
    });
    api.addResponseTransform(response => {
        if ((response.status === 401 || response.status === 403) && !response.headers['no-redirect']) {
          window.location.href = `${process.env.REACT_APP_OAUTH_URL}/login?next=${encodeURI(window.location.href)}`;
        }
    });
    return api;
} 

