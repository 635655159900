import React from 'react';

import { Container, Paper, List, ListItem, ListItemText, Divider } from "@material-ui/core";


import Button from "@material-ui/core/Button";
import { H2 } from '../../components/Heading';

import { useNavigate } from "react-router-dom";
import incidentTypeList from "../../constants/incidentTypeList";


export default function LandingPage() {
    let navigate = useNavigate();
  

    const goto = (type) => {
        navigate(`/incident/${type}`, { replace: true })
    }

    return (<Container maxWidth='md' style={{marginTop: 20}}>
        <H2>Incidents</H2>
        <Paper>
            <List>
                {incidentTypeList.map((incident, index) => {
                    return (
                    <React.Fragment key={index}>
                        <ListItem onClick={() => goto(`${incident.type}`)}>
                            <Button><ListItemText>{incident.label}</ListItemText></Button>
                        </ListItem>
                        {(index < incidentTypeList.length - 1) &&
                            < Divider />
                        }
                    </React.Fragment>
                )})}

            </List>
        </Paper>
        <H2 style={{marginTop: 40}}>Address Validation</H2>
        <Paper style={{padding: 20, fontStyle: 'italic'}}>
            <ListItem onClick={() => navigate('validate-address')}>
                <Button><ListItemText>Validate Address</ListItemText></Button>
            </ListItem>
        </Paper>
    </Container>);
}
